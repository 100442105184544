import React, { useState, useEffect } from 'react';
import { CircularProgress,Select, MenuItem, FormControl, InputLabel, TextField, Button, Table, TableHead, TableBody, TableRow, TableCell,TableContainer,Paper  } from "@mui/material";
import ChartCard from "../shared/ChartCard";
import PageTitle from "../shared/PageTitle";
import KeperluanData from "../../data/keperluan.json";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import { v4 as uuidv4 } from 'uuid';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';


const DaftarPinjam = () => {
    const [jenisPeminjam, setJenisPeminjam] = useState('buku');
    const [keperluan, setKeperluan] = useState(null)
    const [nomor, setNomor] = useState(null)
    const [bukutanah, setBukutanah] = useState([])
    const [elektronik, setElektronik] = useState([])
    const [keperluanBuku, setKeperluanBuku] = useState(null)
    const [keperluanElektronik, setKeperluanElektronik] = useState(null)
    const [listPeminjaman, setListPeminjaman] = useState([])
    const [paket, setPaket] = useState(null)
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [kecamatan, setKecamatan] = useState([]);
    const [selectedKecamatan, setSelectedKecamatan] = useState('');
    const [desa, setDesa] = useState([]);
    const [selectedDesa, setSelectedDesa] = useState('');

    const beUrl = process.env.REACT_APP_BE

    const handleJenisPeminjamChange = (event) => {
        const selectedValue = event.target.value;
        setJenisPeminjam(selectedValue);
        setNomor('');
    };


    const handleListBukutanah = () => {
        try {
            // Cek apakah nomor hak sudah ada di dalam listPeminjaman
            const isExist = listPeminjaman.some(item => item.nomorhak === bukutanah.nomorhak && item.tahunterbit === bukutanah.tahunterbit);
            if (isExist) {
                toast.error("Buku tanah sudah ditambahkan");
                return;
            }
            // Update listPeminjaman state
            const updatedList = [...listPeminjaman, { nomorhak: bukutanah.nomorhak, nomordi208: null, tahun: bukutanah.tahunterbit, jenisPeminjam: 'BUKUTANAH', keperluan: keperluanBuku }];
            setListPeminjaman(updatedList);
            // Reset bukutanah and keperluanBuku states
            setIsSubmitted(false);
            setBukutanah([]);
            setKeperluanBuku(null);
            // setJenisPeminjam("");
            setNomor('');
            setSelectedKecamatan('');
            setSelectedDesa('');
        } catch (error) {
            console.log(error);
        }
    };

    const handleListElektronik = () => {
        try {
            // Cek apakah nomor hak sudah ada di dalam listPeminjaman
            const isExist = listPeminjaman.some(item => item.nomorhak === elektronik.nomorhak && item.tahundibuat === elektronik.tahundibuat);
            if (isExist) {
                toast.error("Sertipikat sudah ditambahkan");
                return;
            }
            // Update listPeminjaman state
            const updatedList = [...listPeminjaman, { nomorhak: elektronik.nomorhak, nomordi208: null,tahun: elektronik.tahundibuat, jenisPeminjam: 'BTEL', keperluan: keperluanElektronik }];
            setListPeminjaman(updatedList);
            // Reset elektronik and keperluanelektronik states
            setIsSubmitted(false);
            setElektronik([]);
            setKeperluanElektronik(null);
            // setJenisPeminjam("");
            setNomor('');
        } catch (error) {
            console.log(error);
        }
    };

    const handleSimpanWarkahbt = () => {
        try {
            const updatedList = listPeminjaman.map(item => {
                return {
                    ...item,
                    paket: paket, 
                    namaPegawai: localStorage.getItem('namaPegawai')
                };
            });
    
            axios.post(`${beUrl}/api/v1/pgsql/warkahbtToList`, { list: updatedList })
            .then(response => {
                // Tampilkan toast berhasil
                toast.success("Pinjaman Anda berhasil disimpan");

                // Setelah simpan berhasil, kosongkan listPeminjaman
                setListPeminjaman([]);

                // Setelah simpan berhasil dan listPeminjaman dikosongkan, panggil fungsi untuk mencetak
                handlePrint();
            });
        } catch (error) {
            console.log(error);
        }
    };

    const getByNibel = () => {
        return new Promise((resolve, reject) => {
            try {
                axios.get(`${beUrl}/api/v1/pgsql/searchNibel`, {
                    params: {
                        nibel: nomor,
                        kecamatan: selectedKecamatan,
                        kelurahan: selectedDesa
                    }
                })
                .then(response => {
                    setElektronik(response.data.data[0]);
                    resolve(); // resolve the promise
                })
          
                .catch(error => {
                    reject(error); // reject the promise if there's an error
                });
            } catch (error) {
                console.log(error);
                reject(error); // reject the promise if there's an error
            }
        });
    }

    const getByNomor = () => {
        return new Promise((resolve, reject) => {
            try {
                axios.get(`${beUrl}/api/v1/pgsql/searchNomorhak`, {
                    params: {
                        nomorhak: nomor,
                        kecamatan: selectedKecamatan,
                        namadesa: selectedDesa
                    }
                })
                .then(response => {
                    if (response.data.data && response.data.data.length > 0) {
                        setBukutanah(response.data.data[0]);
                        resolve(); // resolve the promise
                    } else {
                        toast.error('Data Tidak Ditemukan!'); // show toast message
                        reject(new Error('Data Tidak Ditemukan!')); // reject the promise
                    }
                })
                .catch(error => {
                    toast.error('Terjadi kesalahan saat mengambil data!'); // optional: show toast for request error
                    reject(error); // reject the promise if there's an error
                });
            } catch (error) {
                console.log(error);
                reject(error); // reject the promise if there's an error
            }
        });
    };
    
    

    useEffect(() => {
        // Fetch kecamatan data from API
        axios.get(`${beUrl}/api/v1/pgsql/Kecamatan`)
          .then(response => {
            setKecamatan(response.data.data);
          })
          .catch(error => {
            console.error('Error fetching kecamatan data:', error);
          });
      }, [beUrl]);


      useEffect(() => {
        if (selectedKecamatan) {
          // Fetch desa data based on selected kecamatan
          axios.get(`${beUrl}/api/v1/pgsql/Desa?kecamatan=${selectedKecamatan}`)
            .then(response => {
              setDesa(response.data.data);
            })
            .catch(error => {
              console.error('Error fetching desa data:', error);
            });
        }
      }, [selectedKecamatan,beUrl]);



    const handleDeleteRow = (index) => {
        const updatedList = [...listPeminjaman];
        updatedList.splice(index, 1);
        setListPeminjaman(updatedList);
    };
    
    const handlePrint = () => {
        const contentToPrint = document.getElementById('listPeminjamanTable');
        const namaPegawai = localStorage.getItem('namaPegawai');
        const newWindow = window.open('data:text/html,', '_blank');
        newWindow.document.write('<html><head>');
        // Menambahkan judul dengan posisi di kanan atas
        newWindow.document.write('<title style="position: absolute; top: 0; right: 20px;">Lembar Pinjam</title>');
        newWindow.document.write('<style>h1 { font-size: 24px; text-align: center; }</style>');   
        newWindow.document.write('</head><body>');
        // Menambahkan judul dengan garis bawah
        newWindow.document.write('<h1 style="border-bottom: 1px solid black; padding-bottom: 10px;">Daftar Pinjam Buku Tanah ' + paket + '</h1>');
        newWindow.document.write(contentToPrint.outerHTML);
        // Menambahkan teks "Peminjam:" dengan nama pegawai dan tanda tangan
        newWindow.document.write('<p style="position: absolute; bottom: 180px; left: 20px;">Peminjam:</p>');
        newWindow.document.write('<p style="position: absolute; bottom: 50px; left: 20px;">' + namaPegawai + '</p>');
        // Menambahkan garis bawah
        newWindow.document.write('<hr style="position: absolute; bottom: 30px; left: 20px; width: 200px; border-top: 1px solid black;">');
        newWindow.document.write('<p style="position: absolute; bottom: 10px; left: 20px; color: red; font-size: 12px;">Nb: pada saat melakukan peminjaman dan pengembalian selalu dibawa</p>');
        newWindow.document.write('</body></html>');
        newWindow.document.close();
        newWindow.print();
    };
    

    useEffect(() => {
        if(localStorage.getItem('role') !== 'admin' && localStorage.getItem('role') !== 'peminjam'){
            window.location.href = '/dashboard'
        }
        if(localStorage.getItem('role') === 'admin'){
            setKeperluan('BA/SURAT MENYURAT')
        } else{
            setKeperluan(localStorage.getItem('keperluan'))
        }
        setBukutanah([]);
        setKeperluanBuku(null);
        setElektronik([]);
        setKeperluanElektronik(null);
        setPaket(uuidv4().substring(0, 5))
      }, [listPeminjaman]);


    const handleKecamatanChange = (event) => {
        setSelectedKecamatan(event.target.value);
        setDesa([]); // Reset desa when kecamatan changes
        setSelectedDesa(''); // Reset selected desa
      };
    
      const handleDesaChange = (event) => {
        setSelectedDesa(event.target.value);
      };
      

    return (
        <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-1">
            <PageTitle>Peminjaman Buku Tanah</PageTitle>
            <ChartCard>
                <div className="grid gap-6 mb-6 md:grid-cols-1">
                <div className="grid gap-6  md:grid-cols-2">
                       
                       <form>
                           <TextField
                               variant="outlined"
                               fullWidth
                               value={localStorage.getItem('namaPegawai') || ''} 
                               disabled
                           />
                       </form>
                       <FormControl fullWidth >
                        <InputLabel id="select-label">Keperluan</InputLabel>
                        <Select
                            labelId="select-label"
                            value={keperluan || ''} 
                            onChange={(e) => {setKeperluan(e.target.value)}}
                            variant="outlined"
                            label="Keperluan"
                            disabled={localStorage.getItem('role') === 'admin' ? false : true}
                            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                            >
                            {KeperluanData.data.map((item) => (
                                <MenuItem key={item.idkeperluan} value={item.keperluan}>
                                {item.keperluan}
                            </MenuItem>
                            ))}

                        </Select>
                </FormControl>
                   </div>
                </div>
             
                <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-2">

                    <FormControl fullWidth >
                        <InputLabel id="select-label">Jenis Peminjaman</InputLabel>
                        <Select
                            labelId="select-label"
                            value={jenisPeminjam}
                            onChange={handleJenisPeminjamChange}
                            variant="outlined"
                            label="Jenis Peminjaman"
                            MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                        >
                            <MenuItem value="">Jenis Peminjaman</MenuItem>
                            <MenuItem value="buku">Buku Tanah</MenuItem>
                            <MenuItem value="elektronik">Buku Tanah El</MenuItem>                       
                        </Select>
                    </FormControl>
                    <form >
                        <TextField
                          label={jenisPeminjam === "buku" ? "6 Digit Terakhir Nomor Hak" :  jenisPeminjam === "elektronik" ? "6 Digit Terakhir NIBEL" : "Nomor Hak/Nibel"}
                            variant="outlined"
                            fullWidth
                            value={nomor || ''}
                            inputProps={{
                                maxLength: 6,
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) { // Check if the value is numeric
                                    setNomor(value);
                                }
                            }}
                        />
                    </form>

                    <FormControl fullWidth >
                            <InputLabel id="kecamatan-select-label">Pilih Kecamatan</InputLabel>
                            <Select
                            labelId="kecamatan-select-label"
                            value={selectedKecamatan}
                            onChange={handleKecamatanChange}
                            variant="outlined"
                            label="Pilih Kecamatan"
                            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                            >
                              <MenuItem value="">Pilih Kecamatan</MenuItem>
                            {kecamatan.map((item) => (
                                <MenuItem key={item.kecamatan} value={item.kecamatan.toLowerCase()}>
                                {item.kecamatan}
                                </MenuItem>
                            ))}
                            </Select>
                    </FormControl>

                    <FormControl fullWidth >
                        <InputLabel id="desa-select-label">Pilih Desa</InputLabel>
                        <Select
                        labelId="desa-select-label"
                        value={selectedDesa}
                        onChange={handleDesaChange}
                        variant="outlined"
                        label="Pilih Desa"
                        MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                        disabled={!selectedKecamatan}
                        >
                         <MenuItem value="">Pilih Desa</MenuItem>
                        {desa.map((item) => (
                            <MenuItem key={item.namadesa} value={item.namadesa.toLowerCase()}>
                            {item.namadesa}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                        
                    </div>

    
                
                 <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                 <Button
                    onClick={() => {
                        if (!jenisPeminjam || !nomor || !keperluan || keperluan.trim() === '') {
                            toast.error("Semua kolom harus diisi !");
                            return; 
                        }

                        setLoading(true);

                        if (jenisPeminjam === 'buku') {
                            getByNomor().finally(() => setLoading(false))
                            setKeperluanBuku(keperluan);
                        } else if (jenisPeminjam === 'elektronik') {
                            getByNibel().finally(() => setLoading(false));
                            setKeperluanElektronik(keperluan);
                        }
                        setIsSubmitted(true);
                    }}
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '30px' }}
                >
                    Submit
                </Button>

                </div>
                <ToastContainer position="top-right" />
            </ChartCard>
            
            {isSubmitted && jenisPeminjam !== "" && (
            <>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress />
                </div>
            ) : (
            <>  
            {jenisPeminjam === "buku" && (
            <ChartCard>
            <React.Fragment>
            <TableContainer component={Paper} style={{ marginTop: '20px'  }}>
                <Table>
                    <TableHead>
                        <TableRow>                   
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Pemegang Hak</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Nomor Hak</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>NIB</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Luas Tertulis</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Status BT</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Nama Desa</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Kecamatan</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {bukutanah &&
                        <TableRow>
                            <TableCell align='center'>{bukutanah?.pemeganghak}</TableCell>
                            <TableCell align='center'>{bukutanah?.nomorhak}</TableCell>
                            <TableCell align='center'>{bukutanah?.nib}</TableCell>
                            <TableCell align='center'>{bukutanah?.luastertulis}</TableCell>
                            <TableCell align='center'>{bukutanah?.statusbt}</TableCell>
                            <TableCell align='center'>{bukutanah?.namadesa}</TableCell>
                            <TableCell align='center'>{bukutanah?.kecamatan}</TableCell>

                        </TableRow>
                    }
                </TableBody>

                </Table>
                </TableContainer>
                 <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                 <Button onClick={() => {handleListBukutanah()}} disabled={bukutanah.length === 0} variant="contained" color="primary" style={{ marginTop: '30px', marginLeft: '10px' }}>Tambahkan</Button>
                </div>
                </React.Fragment>
            </ChartCard>
         )}
         {jenisPeminjam === "elektronik" && (
                <ChartCard>
                    <React.Fragment>
                        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white' }}>Pemegang Hak</TableCell>
                                        <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white' }}>Nomor Hak</TableCell>
                                        <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white' }}>NIBEL</TableCell>
                                        <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white' }}>Tipe</TableCell>
                                        <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white' }}>Tipe Hak</TableCell>
                                        <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white' }}>Kecamatan</TableCell>
                                        <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white' }}>Desa</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {elektronik && (
                                        <TableRow>
                                            <TableCell align='center'>{elektronik?.pemeganghak}</TableCell>
                                            <TableCell align='center'>{elektronik?.nomorhak}</TableCell>
                                            <TableCell align='center'>{elektronik?.nibel}</TableCell>
                                            <TableCell align='center'>{elektronik?.tipe}</TableCell>
                                            <TableCell align='center'>{elektronik?.tipehak}</TableCell>
                                            <TableCell align='center'>{elektronik?.kecamatan}</TableCell>
                                            <TableCell align='center'>{elektronik?.kelurahan}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => {handleListElektronik()}} disabled={elektronik.length === 0} variant="contained" color="primary" style={{ marginTop: '30px', marginLeft: '10px' }}>Tambahkan</Button>
                        </div>
                    </React.Fragment>
                </ChartCard>
            )}
           
                </>
                )}
            </>
            )}
            <PageTitle className="print-title">Daftar Pinjaman Saya</PageTitle>
            {
                listPeminjaman.length > 0 ? (
            
            <ChartCard>
            <React.Fragment>
            <TableContainer  style={{ marginTop: '20px'  }}>
            <Table id="listPeminjamanTable" style={{ width: '100%', borderCollapse: 'collapse', margin: '20px 0' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>No</TableCell>
                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Nomor Hak</TableCell>
                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Tahun</TableCell>
                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Jenis Peminjaman</TableCell>
                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Keperluan</TableCell>
                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listPeminjaman && listPeminjaman.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell align='center' style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{index+1}</TableCell>
                                <TableCell align='center' style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{row.nomorhak !== null ? row.nomorhak : (row.nomordi208 !== null ? row.nomordi208 : row.nibel)}</TableCell>
                                <TableCell align='center' style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{row.tahun !== "null" ? row.tahun : '-'}</TableCell>
                                <TableCell align='center' style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{row.jenisPeminjam}</TableCell>
                                <TableCell align='center' style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{row.keperluan}</TableCell>
                                <TableCell align='center' style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>
                                    <IconButton onClick={() => handleDeleteRow(index)}>
                                        <DeleteOutlinedIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </TableContainer>
                 <div className="print-button" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                 <IconButton
                    onClick={handlePrint}
                    disabled={listPeminjaman.length === 0}
                    style={{
                        marginTop: '30px',
                        backgroundColor: '#0288d1',
                        color: 'white',
                        opacity: listPeminjaman.length === 0 ? 0.5 : 1,
                    }}
                    >
                    <LocalPrintshopOutlinedIcon />
                    </IconButton>


                 <Button onClick={() => {handleSimpanWarkahbt()}} disabled={listPeminjaman.length === 0} variant="contained" color="primary" style={{ marginTop: '30px', marginLeft: '10px' }}>Simpan</Button>               
                </div>
                </React.Fragment>
            </ChartCard>
            ) : (
                <ChartCard>
                    <h6 className="text-center text-sm xl:text-lg font-medium">
                        Silahkan Tambahkan Data Peminjaman Terlebih Dahulu
                     </h6>
                </ChartCard>
            )}
            
        </div>
   
    );
}

export default DaftarPinjam;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ChartCard from "../shared/ChartCard";
import PageTitle from "../shared/PageTitle";
import RoundIcon from "../shared/RoundIcon";
import InfoCard from "../shared/InfoCard";
import { CircularProgress  } from '@mui/material';
import { IoNewspaperOutline } from "react-icons/io5";
import { RiBook2Line } from "react-icons/ri";
import { FiPieChart } from "react-icons/fi";
import { MdAccountCircle } from "react-icons/md";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import 'react-circular-progressbar/dist/styles.css';


const DashboardPinjam = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [jumlah, setJumlah] = useState(0);
    const beUrl = process.env.REACT_APP_BE
    const namapeminjam = localStorage.getItem('namaPegawai') || '';

    useEffect(() => {
      const fetchDataCard = async () => {
        try {
          const responseJumlah = await axios.get(`${beUrl}/api/v1/pgsql/JumlahPeminjaman`, {
            params: {
                namapeminjam: namapeminjam,
            }
        });
          
          setJumlah(responseJumlah.data.data);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        }
      };
  
      fetchDataCard();
    }, [namapeminjam, beUrl]);
  
    useEffect(() => {
      if(localStorage.getItem('role') !== 'peminjam' ){
        window.location.href = '/dashboard'
    }

    fetchData();
  }, [namapeminjam, beUrl]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${beUrl}/api/v1/pgsql/BukuWarkah`, {
        params: {
            namapeminjam: namapeminjam,
        },
      });
      setData(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching chart data:', error);
      setIsLoading(false);
    }
  };



  // Konversi nomor bulan menjadi nama bulan
  const convertBulan = (bulan) => {
    const bulanNames = ['Jan', 'Feb', 'Mar', 'Apl', 'Mei', 'Jun', 'Jul', 'Ags', 'Sep', 'Okt', 'Nov', 'Des'];
    return bulanNames[parseInt(bulan, 10) - 1];
  };
      

  return (
<div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-1">
    <PageTitle>Dashboard</PageTitle>
    <div className="grid gap-6  grid-cols-1 xl:grid-cols-4">
        <InfoCard
          title="Hallo Selamat Datang"
          satuan={namapeminjam}
          // value={jumlah && jumlah.length > 0 ? jumlah[0].jumlah_dipinjam : ''}
        >
        <RoundIcon
            icon={MdAccountCircle}
            iconColorClass="text-[#6993FF]"
            bgColorClass="bg-[#BDCFFF]"
            className="mr-4"
          />
        </InfoCard>
        <InfoCard
          title="Total Peminjaman"
          satuan='Peminjaman'
          value={jumlah && jumlah.length > 0 ? jumlah[0].jumlah_dipinjam : ''}
        >
        <RoundIcon
            icon={IoNewspaperOutline}
            iconColorClass="text-[#6993FF]"
            bgColorClass="bg-[#BDCFFF]"
            className="mr-4"
          />
        </InfoCard>
        <InfoCard
          title="Total Tunggakan"
          satuan='Peminjaman'
          value={jumlah && jumlah.length > 0 ? jumlah[0].tunggakan : ''}
        >
          <RoundIcon
            icon={RiBook2Line}
            iconColorClass="text-[#6993FF]"
            bgColorClass="bg-[#BDCFFF]"
            className="mr-4"
          />
        </InfoCard>
        <InfoCard
          title="Total Pengembalian"
          satuan='Peminjaman'
          value={jumlah && jumlah.length > 0 ? jumlah[0].jumlah_dikembalikan : ''}
        >
          <RoundIcon
            icon={FiPieChart}
            iconColorClass="text-[#6993FF]"
            bgColorClass="bg-[#BDCFFF]"
            className="mr-4"
          />
        </InfoCard>
        </div>
    <div className="grid gap-6 mt-5 grid-cols-1 xl:grid-cols-1">

    <ChartCard >
    <h3 style={{ marginBottom: '30px' }} >Rekapitulasi Peminjaman</h3>
    {isLoading ? (
        <CircularProgress style={{ margin: '50px auto', display: 'block' }} />
      ) : (
        <ResponsiveContainer width="100%" height={640}>
          <BarChart data={data} style={{ margin: '0 auto', marginTop: '30px' }} barSize={10}>
            <XAxis dataKey="bulanpinjam" angle={-45} textAnchor="end" interval={0}   tickFormatter={(tick) => convertBulan(tick)} />
            <YAxis/>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              contentStyle={{
                borderRadius: '8px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                padding: '8px',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
              }}
              tickFormatter={(tick) => convertBulan(tick)}
            />
            <Legend verticalAlign="bottom" wrapperStyle={{ paddingBottom: "20px", paddingTop: "80px"}} />
            <Bar dataKey="buku" stackId="a" fill="#0288d1" name="Buku Tanah" />
            <Bar dataKey="warkah" stackId="b" fill="#00dffc" name="Warkah" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </ChartCard >

    </div>
</div>

  );
}
export default DashboardPinjam;
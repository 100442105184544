import React from "react";
import { useEffect } from "react";
import Waiting from "../../components/Pinjam/Waiting";

const WaitingList = () => {
  useEffect(() => {
    document.title = "SIKEBUT - Waiting List";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="p-6">
      <Waiting/>
    </div>
  );
};

export default WaitingList;

import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import ChartCard from "../shared/ChartCard";
import moment from 'moment';
import { CircularProgress, Typography, Stepper, Step, StepLabel } from '@mui/material';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';

const Linimasa = () => {
    const [cardData, setCardData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const beUrl = process.env.REACT_APP_BE;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${beUrl}/api/v1/pgsql/getLinimasa`);
                const data = response.data.data;
                if (Array.isArray(data)) {
                    const sortedData = data.sort((a, b) => new Date(a.tanggal) - new Date(b.tanggal));
                    setCardData(sortedData);
                } else {
                    console.error('Data format is not as expected:', data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
        return () => { };
    }, [beUrl]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const formatDate = (date) => {
        return moment(date).format('DD/MM/YYYY');
    };

    const formatTooltipLabel = (value) => {
        return formatDate(value); // Format date in tooltip
    };

    const renderMobileStepper = () => {
        return (
            <Stepper orientation="vertical" activeStep={5} >
                {cardData.map((item, index) => (
                    <Step key={index} >
                        <StepLabel
                            icon={<ScheduleOutlinedIcon style={{ color: '#0288d1' }} />}
                            optional={<Typography variant="caption">{formatDate(item.tanggal)}</Typography>}
                        >
                            <Typography variant="body2">{`Total: ${item?.jumlah}`}</Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        );
    };

    const renderChart = () => {
        return (
            <ResponsiveContainer width="100%" height={600}>
                <LineChart data={cardData}>
                    <CartesianGrid strokeDasharray="4 4" />
                    <XAxis dataKey="tanggal" tickFormatter={formatDate} />
                    <YAxis />
                    <Tooltip
                        contentStyle={{
                            borderRadius: '8px',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                            padding: '8px',
                            backgroundColor: 'rgba(255, 255, 255, 0.9)'
                        }}
                        labelFormatter={formatTooltipLabel}
                    />
                    <Legend />
                    <Line type="monotone" dataKey="jumlah" name="Jumlah" stroke="#0288d1" />
                </LineChart>
            </ResponsiveContainer>
        );
    };

    return (
        <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-1">
            {isLoading ? (
                <CircularProgress style={{ margin: '50px auto', display: 'block' }} />
            ) : (
                cardData.length === 0 ? (
                    <Typography variant="h6" style={{ textAlign: 'center', margin: '50px auto' }}>
                        Data Linimasa Anda Belum Ada
                    </Typography>
                ) : (
                    <ChartCard>
                        {isMobile ? renderMobileStepper() : renderChart()}
                    </ChartCard>
                )
            )}
        </div>
    );
}

export default Linimasa;

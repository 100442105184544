import React from "react";
import { useEffect } from "react";
import LaporanBT from "../../components/JatuhTempo";

const Laporan = () => {
  useEffect(() => {
    document.title = "SIKEBUT - Laporan";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="p-6">
      <LaporanBT/>
    </div>
  );
};

export default Laporan;

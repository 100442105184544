import React from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Toolbar, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import SwitchAccountOutlinedIcon from '@mui/icons-material/SwitchAccountOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';

const drawerWidth = 270;

const Sidebar = ({ mobileOpen, handleDrawerToggle }) => {


  const drawer = (
    <Box sx={{ backgroundColor: '#1976d2', color: 'white', height: '100%' }}>
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {
          localStorage.getItem('role') !== 'admin'  ? <></> :
          <ListItem button component={Link} to="/dashboard" onClick={() => handleDrawerToggle(false)}>
            <ListItemIcon style={{ color: 'white' }}>
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" style={{ color: 'white' }} />
          </ListItem>
          }
           {
           localStorage.getItem('role') !== 'peminjam' ? <></> :
          <ListItem button component={Link} to="/dashboardpeminjam" onClick={() => handleDrawerToggle(false)}>
            <ListItemIcon style={{color:'white'}}>
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" style={{color:'white'}} />
          </ListItem>
          }
            {
           localStorage.getItem('role') !== 'petugas' && localStorage.getItem('role') !== 'tu' ? <></> :
          <ListItem button component={Link} to="/dashboardpetugas" onClick={() => handleDrawerToggle(false)}>
            <ListItemIcon style={{color:'white'}}>
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" style={{color:'white'}} />
          </ListItem>
          }
          {
          localStorage.getItem('role') !== 'admin' && localStorage.getItem('role') !== 'peminjam' ? <></> :
          <ListItem button component={Link} to="/pinjam/peminjaman" onClick={() => handleDrawerToggle(false)}>
            <ListItemIcon style={{color:'white'}}>
              <LibraryBooksOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Peminjaman BT" style={{color:'white'}} />
          </ListItem>
          }
          {
          localStorage.getItem('role') !== 'admin' && localStorage.getItem('role') !== 'peminjam' ? <></> :
          <ListItem button component={Link} to="/pinjam/peminjamanwarkah" onClick={() => handleDrawerToggle(false)}>
            <ListItemIcon style={{color:'white'}}>
              <StyleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Peminjaman Warkah" style={{color:'white'}} />
          </ListItem>
          }
          
           {
          localStorage.getItem('role') !== 'peminjam' ? <></> :
          <ListItem button component={Link} to="/pinjam/logpinjam" onClick={() => handleDrawerToggle(false)}>
            <ListItemIcon style={{color:'white'}}>
              <HistoryOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Riwayat Peminjaman" style={{color:'white'}} />
          </ListItem>
          }
          
          {
          localStorage.getItem('role') !== 'admin' && localStorage.getItem('role') !== 'petugas' ? <></> :
          <ListItem button component={Link} to="/pinjam/daftarpinjam"  onClick={() => handleDrawerToggle(false)}>
          <ListItemIcon style={{color:'white'}}>
            <BallotOutlinedIcon />
          </ListItemIcon>
            <ListItemText primary="Daftar Peminjaman" style={{color:'white'}} />
          </ListItem>
          }
          {
          localStorage.getItem('role') !== 'admin' && localStorage.getItem('role') !== 'tu' ? <></> :
          <ListItem button component={Link} to="/pengembalian/pengembalian" onClick={() => handleDrawerToggle(false)}>
            <ListItemIcon style={{ color: 'white' }}>
              <StickyNote2OutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Pengembalian" style={{ color: 'white' }} />
          </ListItem>
          }
          {
            localStorage.getItem('role') !== 'admin' && localStorage.getItem('role') !== 'petugas' ? <></> :
          <ListItem button component={Link}  to="/pengembalian/listKembali" onClick={() => handleDrawerToggle(false)}>
            <ListItemIcon style={{color:'white'}}>
              <TaskAltOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Daftar Pengembalian" style={{color:'white'}} />
          </ListItem>
          }
          {
           localStorage.getItem('role') !== 'petugas' ? <></> :
          <ListItem button component={Link} to="/pinjam/waitinglist" onClick={() => handleDrawerToggle(false)}>
            <ListItemIcon style={{color:'white'}}>
              <AccessTimeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Waiting List" style={{color:'white'}} />
          </ListItem>
          }
          {
           localStorage.getItem('role') !== 'petugas' && localStorage.getItem('role') !== 'admin' ? <></> :
          <ListItem button component={Link} to="/pinjam/tambahbt" onClick={() => handleDrawerToggle(false)}>
            <ListItemIcon style={{color:'white'}}>
              <AddCardOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Tambah BT/Warkah" style={{color:'white'}} />
          </ListItem>
          }
          {
           localStorage.getItem('role') !== 'petugas' ? <></> :
          <ListItem button component={Link} to="/pinjam/pencarian" onClick={() => handleDrawerToggle(false)}>
            <ListItemIcon style={{color:'white'}}>
              <ManageSearchOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Pencarian Peminjaman" style={{color:'white'}} />
          </ListItem>
          }
          {
            localStorage.getItem('role') !== 'admin' ?
            <></>
            :
          <ListItem button component={Link} to="/pinjam/riwayat" onClick={() => handleDrawerToggle(false)}>
            <ListItemIcon style={{color:'white'}}>
              <HistoryOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="History" style={{color:'white'}} />
          </ListItem>
          }

          {
            localStorage.getItem('role') !== 'admin' ?
            <></>
            :
            <ListItem button component={Link} to="/pegawai/petugas" onClick={() => handleDrawerToggle(false)}>
            <ListItemIcon style={{color:'white'}}>
              <SwitchAccountOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Manajemen Pengguna" style={{color:'white'}} />
          </ListItem>
          }
          
          {
            localStorage.getItem('role') !== 'admin' ?
            <></>
            :
          <ListItem button component={Link} to="/laporan/laporan" onClick={() => handleDrawerToggle(false)}>
            <ListItemIcon style={{color:'white'}}>
              <LayersOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Laporan" style={{color:'white'}} />
          </ListItem>
          }
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { 
            width: drawerWidth, 
            boxSizing: 'border-box', 
            backgroundColor: '#1976d2', 
            color: 'white',
          },
          display: { xs: 'none', md: 'block' }  // Show permanently on larger screens
        }}
        open
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },  // Show temporarily on smaller screens
          '& .MuiDrawer-paper': { 
            boxSizing: 'border-box', 
            width: drawerWidth, 
            backgroundColor: '#1976d2', 
            color: 'white',
          },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default Sidebar;

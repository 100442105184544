import React, { useState, useEffect } from 'react';
import { CircularProgress,Select, MenuItem, FormControl, InputLabel, TextField, Button, Table, TableHead, TableBody, TableRow, TableCell,TableContainer,Paper  } from "@mui/material";
import ChartCard from "../shared/ChartCard";
import PageTitle from "../shared/PageTitle";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DaftarKembali = () => {
    const [jenisPeminjam, setJenisPeminjam] = useState('');
    const [namaPetugas, setNamaPetugas] = useState('');
    const [fetchdata, setFetchdata] = useState([])
    const beUrl = process.env.REACT_APP_BE
    const [isLoading, setIsLoading] = useState(true);
    const [showSelectTahun, setShowSelectTahun] = useState(false);
    const [selectedYear, setSelectedYear] = useState('');
    const [nomor, setNomor] = useState(null);

    const fetchDataVerifPengembalian = async () => {
        try {
            const response = await axios.get(`${beUrl}/api/v1/pgsql/listVerifPengembalian`);
            setFetchdata(response.data.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmitAfterVerifikasi = async () => {
        try {
            const response = await axios.get(`${beUrl}/api/v1/pgsql/listVerifPengembalian`);
            // Membuat objek checkedItems baru yang mengatur semua status awal menjadi false
            setFetchdata(response.data.data);
       
        } catch (error) {
            setFetchdata([]);
      
            toast.error('Daftar pengembalian sudah habis');
        }
    };

    const handleKonfirmasi = async (row) => {
        try {
            axios.post(`${beUrl}/api/v1/pgsql/kembali`, row)
            .then(response => {
                // Tampilkan toast berhasil
                toast.success("Pengembalian anda berhasil !");
            })
            .then(() => {
                handleSubmitAfterVerifikasi()
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        let url = '';
        
        if (jenisPeminjam === 'buku') {
            url = `${beUrl}/api/v1/pgsql/CariPengembalianBT?nomorhak=${nomor}`;
        } else if (jenisPeminjam === 'warkah') {
            url = `${beUrl}/api/v1/pgsql/CariPengembalianWarkah?nomordi208=${nomor}&tahun=${selectedYear}`;
        }
        
        try {
            const response = await axios.get(url);
            setFetchdata(response.data.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error searching data:', error);
            toast.error('Data Tidak Ditemukan');
        }
    };
    

    useEffect(() => {
        if(localStorage.getItem('role') !== 'admin' && localStorage.getItem('role') !== 'petugas'){
            window.location.href = '/dashboard'
        }
        setNamaPetugas(localStorage.getItem('namaPegawai'))
        fetchDataVerifPengembalian();
    }, []);


    const handleJenisPeminjamChange = (event) => {
        const selectedValue = event.target.value;
        setJenisPeminjam(selectedValue);
        setShowSelectTahun(selectedValue === "warkah");
        setNomor('');
        setSelectedYear('');
    };


    return (
        <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-1">
            <PageTitle>Pengembalian Buku Tanah dan Warkah</PageTitle>
            <ChartCard>
                <div className="grid gap-6 mb-6 md:grid-cols-1">
                <form>
                           <TextField
                               variant="outlined"
                               fullWidth
                               value={localStorage.getItem('namaPegawai')}
                               disabled
                           />
                       </form>
                <div className={`grid gap-6 mb-6 ${jenisPeminjam === "warkah" ? 'md:grid-cols-4' : 'md:grid-cols-3'}`}>
                       
                    
                       <FormControl fullWidth>
                        <InputLabel id="select-label">Pilih Pencarian</InputLabel>
                        <Select
                            labelId="select-label"
                            value={jenisPeminjam}
                            onChange={handleJenisPeminjamChange}
                            variant="outlined"
                            label="Pilih Pencarian"
                            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                        >
                            <MenuItem value="">Pilih Pencarian</MenuItem>
                            <MenuItem value="buku">Buku Tanah</MenuItem>
                            <MenuItem value="warkah">Warkah</MenuItem>
                        </Select>
                    </FormControl>
                    <form>
                        <TextField
                          label={jenisPeminjam === "buku" ? "6 Digit Terakhir Nomor Hak" : jenisPeminjam === "warkah" ? "Nomor DI208" : "Nomor Hak/Warkah"}
                            variant="outlined"
                            fullWidth
                            value={nomor || ''}
                            inputProps={{
                                maxLength: 6,
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) { // Check if the value is numeric
                                    setNomor(value);
                                }
                            }}
                        />
                    </form>
                  
            
                    {showSelectTahun && (

                        <TextField
                       
                            label='Tahun DI208'
                            variant="outlined"
                            fullWidth
                            value={selectedYear || ''}
                            inputProps={{
                                maxLength: 4,
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) { // Check if the value is numeric
                                    setSelectedYear(value);
                                }
                            }}
                        />
                        )}
                          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Button variant="contained" color="primary" onClick={handleSubmit}  >Submit</Button>
                        </div>
                   </div>
                </div>
             
            <ToastContainer position="top-right" />
            </ChartCard>
            
            {fetchdata && fetchdata.length > 0 ? (
            <ChartCard>
            <React.Fragment>
            {isLoading ? (
                <CircularProgress style={{ margin: '50px auto', display: 'block' }} />
            ) : (
            <TableContainer component={Paper} style={{ marginTop: '20px'  }}>
                <Table>
                    <TableHead>
                        <TableRow>                   
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>No</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Nama Peminjam</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Nomor Hak/Warkah</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Tahun</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Jenis Peminjaman</TableCell>              
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Tanggal Pinjam</TableCell>    
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Keperluan</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Paket</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Konfirmasi</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {fetchdata && fetchdata.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{index+1}</TableCell>
                            <TableCell>{row.namapeminjam}</TableCell>
                            <TableCell align='center'>{row.nomorhak !== 'NULL' ? row.nomorhak : row.nomordi208}</TableCell>                    
                            <TableCell align='center'>{row.tahun !== "null" ? row.tahun : '-'}</TableCell>
                            <TableCell align='center'>{row.jenis}</TableCell>
                            <TableCell align='center'>{row.tglpinjam}</TableCell>
                            <TableCell align='center'>{row.keperluan}</TableCell>
                            <TableCell align='center'>{row.paket}</TableCell>

                            <TableCell align='center'><Button onClick={() => {handleKonfirmasi(row)}} variant="contained" size="small" color="primary">Konfirmasi</Button></TableCell>

                        </TableRow>
                    ))}
                </TableBody>

                </Table>
                </TableContainer>
                  )}
                </React.Fragment>
            </ChartCard>
        ) : (
                <ChartCard>
                    <h6 className="text-center text-sm xl:text-lg font-medium">
                        Silahkan Menunggu Pengembalian Berikutnya
                    </h6>
                </ChartCard>
            )}

        </div>
   
    );
}

export default DaftarKembali;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ChartCard from "../shared/ChartCard";
import PageTitle from "../shared/PageTitle";
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Paper, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const PencarianBT = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [status, setStatus] = useState('');
    const [pencarian, setPencarian] = useState('');
    const [inputType, setInputType] = useState(''); 
    const [namaKeperluan, setNamaKeperluan] = useState([]);
    const itemsPerPage = 10; 
    const beUrl = process.env.REACT_APP_BE;

    useEffect(() => {
        fetchSelectData();
    }, [beUrl]);

    const fetchSelectData = async () => {
        try {        
            const cardResponse = await axios.get(`${beUrl}/api/v1/pgsql/getPetugas2`);
            const filteredData = cardResponse.data.data.filter(item => item.role === 'peminjam');
            setNamaKeperluan(filteredData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (localStorage.getItem('role') !== 'petugas') {
            window.location.href = '/dashboard';
        }
    }, []);

    const fetchData = async () => {
        if (!status || !pencarian.trim()) {
            return;
        }
        setIsLoading(true);
        let apiUrl = '';
        let params = {
            awal: (currentPage - 1) * itemsPerPage,
            akhir: itemsPerPage
        };

        if (status === 'nama') {
            apiUrl = `${beUrl}/api/v1/pgsql/searchHistoryByName`;
            params = { ...params, namapeminjam: pencarian };
        } else if (status === 'nomor') {
            apiUrl = `${beUrl}/api/v1/pgsql/searchHistory`;
            params = { ...params, nomorhak: pencarian };
        } else if (status === 'id') {
            apiUrl = `${beUrl}/api/v1/pgsql/searchHistoryById`;
            params = { ...params, paket: pencarian };
        }

        try {
            const response = await axios.get(apiUrl, { params });
            setData(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setIsLoading(false);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        fetchData();
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
        fetchData();
    };

    const handlePencarianChange = (event) => {
        setPencarian(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
        setPencarian('');
        setData([]); // Clear data to hide the table
        if (event.target.value === 'nomor') {
            setInputType('number');
        } else {
            setInputType('text');
        }
    };

    const handleSearch = () => {
        setCurrentPage(1);
        fetchData();
    };

    return (
        <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-1">
            <PageTitle>Pencarian Peminjaman</PageTitle>

            <ChartCard>
                <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-3">
                    <FormControl fullWidth>
                        <InputLabel id="select-label">Pilih Pencarian</InputLabel>
                        <Select
                            labelId="select-label"
                            value={status}
                            onChange={handleStatusChange}
                            variant="outlined"
                            label="Pilih Pencarian"
                            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                        >
                            <MenuItem value="">Pilih Pencarian</MenuItem>
                            <MenuItem value="nama">Nama Peminjam</MenuItem>
                            <MenuItem value="id">ID Pinjam</MenuItem>
                            <MenuItem value="nomor">Nomor Hak</MenuItem>
                        </Select>
                    </FormControl>

                    {status === 'nama' && (
                        <FormControl fullWidth>
                            <InputLabel id="nama-select-label">Pilih Peminjam</InputLabel>
                            <Select
                                labelId="nama-select-label"
                                value={pencarian}
                                onChange={handlePencarianChange}
                                variant="outlined"
                                label="Pilih Peminjam"
                            >
                                <MenuItem value="">Pilih Peminjam</MenuItem>
                                {namaKeperluan.map((item) => (
                                    <MenuItem key={item.nama} value={item.nama}>
                                        {item.nama}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    {(status === 'id' || status === 'nomor') && (
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={pencarian}
                            onChange={handlePencarianChange}
                            type={inputType}
                        />
                    )}
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Button variant="contained" color="primary" onClick={handleSearch} disabled={!pencarian.trim() || !status}>Submit</Button>
                    </div>
                </div>
            </ChartCard>

            {data && data.length > 0 ? (
                <ChartCard>
                    {isLoading ? (
                        <CircularProgress style={{ margin: '50px auto', display: 'block' }} />
                    ) : (
                        <React.Fragment>
                            <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white' }}>No</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white' }}>Nama Peminjam</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white' }}>Nomor Hak/Warkah</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white' }}>Tahun</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white' }}>Jenis Peminjaman</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white' }}>Keperluan</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white' }}>Tanggal Pinjam</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white' }}>Tanggal Kembali</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white' }}>Durasi</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white' }}>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((item, index) => {
                                            const rowNumber = (currentPage - 1) * itemsPerPage + index + 1;
                                            let statusText;
                                            let statusColor;
                                            if (item.status === null) {
                                                statusText = 'Waiting';
                                                statusColor = '#ed9b40';
                                            } else if (item.status === '1' && item.status_pengembalian === '2') {
                                                statusText = 'Selesai';
                                                statusColor = '#61c9a8';
                                            } else if (item.status === '1' && item.status_pengembalian === '1' && item.status_tu ==='1') {
                                                statusText = 'Pengembalian';
                                                statusColor = '#0288d1';
                                            } else if (item.status === '1' && item.status_pengembalian === null && item.status_tu === null) {
                                                statusText = 'Dipinjam';
                                                statusColor = '#d64550';
                                            }
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell align="center">{rowNumber}</TableCell>
                                                    <TableCell align="center">{item.namapeminjam}</TableCell>
                                                    <TableCell align="center">{item.nomorhak !== "NULL" ? item.nomorhak : item.nomordi208}</TableCell>
                                                    <TableCell align="center">{item.tahun !== "null" ? item.tahun : '-'}</TableCell>
                                                    <TableCell align="center">{item.jenis}</TableCell>
                                                    <TableCell align="center">{item.keperluan}</TableCell>
                                                    <TableCell align="center">{item.tglpinjam !== null ? item.tglpinjam : '-'}</TableCell>
                                                    <TableCell align="center">{item.tglkembali !== null ? item.tglkembali : '-'}</TableCell>
                                                    <TableCell align="center">{item.durasi}</TableCell>
                                                    <TableCell align="center" style={{ color: statusColor }}>{statusText}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div style={{ display: 'flex', justifyContent: 'right', marginTop: '20px' }}>
                                <Button onClick={handlePreviousPage} disabled={currentPage === 1} variant="contained" color="warning" size="small" style={{ marginRight: '10px' }}>Previous</Button>
                                <Button onClick={handleNextPage} disabled={data?.length < itemsPerPage} variant="contained" size="small">Next</Button>
                            </div>
                        </React.Fragment>
                    )}
                </ChartCard>
            ) : (
                <ChartCard>
                    <h6 className="text-center text-sm xl:text-lg font-medium">
                        Silahkan Pilih Kategori Pencarian Terlebih Dahulu
                    </h6>
                </ChartCard>
            )}
        </div>
    );
}

export default PencarianBT;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton,Dialog,DialogTitle,DialogContent,DialogActions,Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import logo from '../assets/images/lg.png'; // Pastikan path ini benar

const Header = ({ handleLogout,handleDrawerToggle }) => {
    const history = useHistory();
    const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

    const handleOpenLogoutDialog = () => {
        setOpenLogoutDialog(true);
      };
    
      const handleCloseLogoutDialog = () => {
        setOpenLogoutDialog(false);
      };

      const handleLogoutClick = () => {
        localStorage.clear();
        handleLogout();
        history.push('/Auth');
        setOpenLogoutDialog(false);
      }

    return (
        <>
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#1976d2' }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <img src={logo} alt="Logo" style={{ marginRight: '10px', height: '30px' }} />
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              SI KEBUT
            </Typography>
            <IconButton color="inherit" aria-label="account" onClick={handleOpenLogoutDialog}>
              <AccountCircleIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Dialog open={openLogoutDialog} onClose={handleCloseLogoutDialog}>
        <DialogTitle>Logout</DialogTitle>
        <DialogContent>
            <Typography variant="body1">Apakah Anda yakin ingin keluar?</Typography>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCloseLogoutDialog} color="primary">Cancel</Button>
        <Button onClick={handleLogoutClick} color="primary">Logout</Button>
        </DialogActions>
        </Dialog>
         </>
      );
    };

export default Header;

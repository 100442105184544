import React from "react";
import { Route, Switch,Redirect  } from "react-router-dom";
import Auth from "../pages/Auth";
import Dashboard from './../pages/Dashboard';
import DashboardTugas from './../pages/DashboardPetugas';
import DashboardPeminjam from './../pages/DashboardPeminjam';
import Peminjaman from "../pages/Pinjam/Peminjaman";
import PeminjamanWarkah from "../pages/Pinjam/PeminjamanWarkah";
import DaftarPeminjaman from "../pages/Pinjam/DaftarPinjam";
import DaftarKembali from "../pages/Pengembalian/ListKembali";
import Kembali from "../pages/Pengembalian/Pengembalian";
import Waiting from "../pages/Pinjam/WaitingList";
import TambahBT from "../pages/Pinjam/TambahBT";
import Pencarian from "../pages/Pinjam/Pencarian";
// import History from "../pages/Pinjam/History";
import Riwayat from "../pages/Pinjam/RiwayatPinjam";
import LogPinjam from "../pages/Pinjam/LogPinjam";
import Petugas from "../pages/Pegawai/Petugas";
import LaporanBT from "../pages/Laporan/Laporan";



const Routes = () => {

  const role = localStorage.getItem('role');

  const renderDashboard = () => {
    if (role === 'admin') {
      return <Dashboard />;
    } else if (role === 'peminjam') {
      return <DashboardPeminjam />;
    } else if (role === 'petugas' ||  role === 'tu') {
      return <DashboardTugas />;
    } else {
      return <Redirect to="/" />;
    }
  };

  return (
    <Switch>
      <Route exact path="/Auth" component={Auth} />
      <Route exact path="/" render={renderDashboard} />
      {/* <Route exact path="/" component={Dashboard} /> */}
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/dashboardpetugas" component={DashboardTugas} />
      <Route exact path="/dashboardpeminjam" component={DashboardPeminjam} />
      <Route exact path="/pinjam/peminjaman" component={Peminjaman} />
      <Route exact path="/pinjam/peminjamanWarkah" component={PeminjamanWarkah} />
      <Route exact path="/pinjam/daftarpinjam" component={DaftarPeminjaman} />
      <Route exact path="/pengembalian/listKembali" component={DaftarKembali} />
      <Route exact path="/pengembalian/pengembalian" component={Kembali} />
      <Route exact path="/pinjam/waitinglist" component={Waiting} />
      <Route exact path="/pinjam/tambahbt" component={TambahBT} />
      <Route exact path="/pinjam/pencarian" component={Pencarian} />
      {/* <Route exact path="/pinjam/history" component={History} /> */}
      <Route exact path="/pinjam/riwayat" component={Riwayat} />
      <Route exact path="/pinjam/logpinjam" component={LogPinjam} />
      <Route exact path="/pegawai/petugas" component={Petugas} />
      <Route exact path="/laporan/laporan" component={LaporanBT} />
    </Switch>
  );
};

export default Routes;

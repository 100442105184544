import React, { useState } from 'react';
import { styled } from '@mui/system';
import { useHistory } from 'react-router-dom';
import { TextField, Button, Typography,useMediaQuery,IconButton   } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import logo from '../assets/images/lg.png';
import axios from 'axios';



const FormContainer = styled('div')({
  marginTop: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  height: '80vh',
  paddingLeft: '40px',
});



const Login = ({ handleLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const beUrl = process.env.REACT_APP_BE
  const isMobile = useMediaQuery('(max-width:600px)');


  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      axios.get(`${beUrl}/api/v1/pgsql/login?email=${username}&nipbaru=${password}`)
      .then(({data}) =>{
        if(data?.data[0]?.status === 'aktif'){

          if(data?.data[0] && data?.data[0]?.role !== null){
      
            localStorage.setItem("role", data?.data[0]?.role)
            localStorage.setItem("namaPegawai", data?.data[0]?.namapetugas)
            localStorage.setItem("keperluan", data?.data[0]?.keperluan)
            toast.success('Login berhasil!');
            // history.push('/dashboard');
            handleLogin();
            const role = data?.data[0]?.role;
            if (role === 'admin') {
              history.push('/dashboard');
            } else if (role === 'peminjam') {
              history.push('/dashboardpeminjam');
            } else if (role === 'petugas' || role === 'tu') {
              history.push('/dashboardpetugas');
            } else {
              toast.error('Role tidak dikenal');
            }

          } else{
            toast.success('Login Gagal!');
          }
  
        } else if(data?.data[0] && data?.data[0]?.status !== 'aktif'){
          toast.error('Status akun tidak aktif.');
        } else{
          toast.error('Anda Belum Terdaftar Sebagai Petugas');
        }
      })
      .catch(() => {
        toast.error('Anda Belum Terdaftar Sebagai Petugas');
      })
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Terjadi kesalahan saat mencoba login. Silakan coba lagi.');
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  

  const backgroundImageStyle = {
    backgroundImage: `url(${require('../assets/images/bgs.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100vh',
  };

  return (
    <div style={backgroundImageStyle}>
  
      <FormContainer style={{ marginTop: 'auto', marginBottom: 'auto' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '50px' }}>
          <img src={logo} alt="Logo" style={{ width: '55px', height: '45px', marginRight: '8px' }} />
          <div>
            <p style={{ fontSize: '16px' }} >
              Sistem Kendali Buku Tanah
            </p>
          </div>
        </div>
        <Typography component="h1" variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
          Login Aplikasi
        </Typography>
        <Typography variant="h7" color="text.secondary">
          Silahkan masuk untuk memulai aplikasi
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            name="username"
            autoComplete="username"
            sx={{ width: isMobile ? '90%' : '70%', marginTop: '30px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="password"
            autoComplete="current-password"
            sx={{ width: isMobile ? '90%' : '70%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
       <Button
          type="submit"
          fullWidth
          variant="contained"
          color="info"
          sx={{ height: '50px', width: isMobile ? '90%' : '70%', marginTop: '80px' }}
        >
          Login
        </Button>

        </form>
        <ToastContainer position="top-right" />
      </FormContainer>
    
    </div>
  );
};

export default Login;

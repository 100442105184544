import React from "react";
import { useEffect } from "react";
import DaftarKembali from "../../components/Pengembalian/DaftarKembali";

const ListKembali = () => {
  useEffect(() => {
    document.title = "SIKEBUT - Daftar Pengembalian";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="p-6">
      <DaftarKembali/>
    </div>
  );
};

export default ListKembali;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ChartCard from "../shared/ChartCard";
import PageTitle from "../shared/PageTitle";
import RoundIcon from "../shared/RoundIcon";
import InfoCard from "../shared/InfoCard";
import { CircularProgress,Table, TableBody, TableCell, TableContainer, TableRow, TableHead,Paper,Button,Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { MdAccountCircle } from "react-icons/md";
import { GoClock } from "react-icons/go";
import DescriptionIcon from '@mui/icons-material/Description';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Waiting = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [rekap, setRekap] = useState(0);
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const itemsPerPage = 10; 
    const [openDialog, setOpenDialog] = useState(false);
    const [detailData, setDetailData] = useState([]);
    const [selectedPaket, setSelectedPaket] = useState(null);
    const [selectedNamaPeminjam, setSelectedNamaPeminjam] = useState(null);
    const beUrl = process.env.REACT_APP_BE

    useEffect(() => {
      const fetchDataCard = async () => {
        try {
          const responseRekap = await axios.get(`${beUrl}/api/v1/pgsql/JumlahWaitingList`);
          
          setRekap(responseRekap.data.data);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        }
      };
  
      fetchDataCard();
    }, [beUrl]);
  
    useEffect(() => {
      if(localStorage.getItem('role') !== 'petugas'){
        window.location.href = '/dashboard'
    }

    fetchData();
  }, [currentPage,beUrl]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
        const offset = (currentPage - 1) * itemsPerPage;
        const response = await axios.get(`${beUrl}/api/v1/pgsql/WaitingList`, {
            params: {
                awal: offset,
                akhir: itemsPerPage
            }
        });
        setData(response.data.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    setIsLoading(false);
};

      
const handleNextPage = () => {
  setIsLoading(true); 
  setCurrentPage(currentPage + 1);
};

const handlePreviousPage = () => {
  setIsLoading(true); 
  setCurrentPage(currentPage - 1);
};


const handleOpenDialog = async (paket, namapeminjam) => {
  try {
      const response = await axios.get(`${beUrl}/api/v1/pgsql/DetailWaitingList`, { params: { paket } });
      setDetailData(response.data.data);
      setSelectedPaket(paket);
      setSelectedNamaPeminjam(namapeminjam);
      setOpenDialog(true);
  } catch (error) {
      console.error('Error fetching detail data:', error);
  }
};

const handleCloseDialog = () => {
  setOpenDialog(false);
  setDetailData([]);
};



  return (
    <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-1">
    <PageTitle>Waiting List Peminjaman Buku Tanah dan Warkah</PageTitle>
    <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-2">
    <InfoCard
          title="Total Waiting"
          satuan='Peminjaman'
          value={rekap && rekap?.length > 0 ? rekap[0].waiting : ''}
        >
        <RoundIcon
            icon={GoClock}
            iconColorClass="text-[#6993FF]"
            bgColorClass="bg-[#BDCFFF]"
            className="mr-4"
          />
        </InfoCard>
        <InfoCard
          title="Jumlah User Peminjam"
          satuan='User'
          value={rekap && rekap?.length > 0 ? rekap[0].users_waiting : ''}
        >
          <RoundIcon
            icon={MdAccountCircle}
            iconColorClass="text-[#6993FF]"
            bgColorClass="bg-[#BDCFFF]"
            className="mr-4"
          />
        </InfoCard>
        
    </div>
   

    {data && data?.length > 0 ? (
    <ChartCard> 
       {isLoading ? ( 
        <CircularProgress style={{ margin: '50px auto', display: 'block' }} />
        ) : (
        <React.Fragment>
        <TableContainer component={Paper} style={{ marginTop: '10px'  }}>
        <Table>
          <TableHead>
              <TableRow>
              <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>No</TableCell>
              <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Nama Pemijam</TableCell> 
              <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>ID Pinjam</TableCell>  
              <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Tanggal Input</TableCell> 
              <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Total Waiting</TableCell> 
              <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Detail</TableCell>            
              </TableRow>
        </TableHead>
            <TableBody>
                  {data?.map((item, index) => {
                    const rowNumber = (currentPage - 1) * itemsPerPage + index + 1; // Calculate row number

                    return (
                      <TableRow key={index}>
                        <TableCell align="center">{rowNumber}</TableCell>
                        <TableCell align="left">{item.namapeminjam}</TableCell>
                        <TableCell align="center">{item.paket}</TableCell>
                        <TableCell align="center">{item.tglinput}</TableCell>
                        <TableCell align="center">{item.jumlah_waiting}</TableCell>
                        <TableCell align='center'>
                        <Button variant="outlined" color="primary" onClick={() => handleOpenDialog(item.paket, item.namapeminjam)}>
                            <DescriptionIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

          </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'right', marginTop: '20px' }}>
              <Button onClick={handlePreviousPage} disabled={currentPage === 1} variant="contained" color="warning" size="small" style={{ marginRight: '10px' }}>Previous</Button>
              <Button onClick={handleNextPage} disabled={data?.length < itemsPerPage} variant="contained" size="small">Next</Button>
          </div>
          </React.Fragment>
      )}
    </ChartCard>
        ) : (
        <ChartCard>
            <h6 className="text-center text-sm xl:text-lg font-medium">
                Silahkan Menunggu Waiting List Berikutnya
            </h6>
        </ChartCard>
        )}
    
    <Dialog open={openDialog} onClose={handleCloseDialog} TransitionComponent={Transition} maxWidth="lg" fullWidth>
          <DialogTitle>Detail Peminjaman - {selectedNamaPeminjam} ( {selectedPaket} )</DialogTitle>
          <DialogContent>
              <TableContainer>
              <Table>
                      <TableHead>
                          <TableRow>
                          <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>No</TableCell>
                          <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Nomor Hak/Warkah</TableCell>
                          <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Tahun</TableCell>
                          <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Jenis Peminjamman</TableCell>
                          <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Keperluan</TableCell>
                          <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Status</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                        {detailData.map((detail, index) => {

                          const status = detail.status === null ? "Waiting" : detail.status;

                            
                            return (
                                <TableRow key={index}>
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell align="center">{detail.nomorhak !== "NULL" ? detail.nomorhak : detail.nomordi208}</TableCell>
                                    <TableCell align="center">{detail.tahun !== "null" ? detail.tahun : '-'}</TableCell>
                                    <TableCell align="center">{detail.nomorhak !== 'NULL' ? 'BUKU TANAH' : 'WARKAH'}</TableCell>
                                    <TableCell align="center">{detail.keperluan}</TableCell>
                                    <TableCell align="center" style={{color: '#ed9b40' }}>{status}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                  </Table>
              </TableContainer>
          </DialogContent>
          <DialogActions>
              <Button  variant="contained" onClick={handleCloseDialog} color="primary">
                  Close
              </Button>
          </DialogActions>
      </Dialog>
  </div>

  );
}
export default Waiting;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ChartCard from "./shared/ChartCard";
import PageTitle from "./shared/PageTitle";
import InfoCard from "./shared/InfoCard";
import RoundIcon from "./shared/RoundIcon";
import { IoLogoBuffer } from "react-icons/io";
import { FaBook } from "react-icons/fa";
import { MdLibraryBooks } from "react-icons/md";
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Button,Card 
} from '@mui/material';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { IoIosNotifications } from "react-icons/io";

const JatuhTempo = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [bukutanah, setBukuTanah] = useState([]);
    const [warkah, setWarkah] = useState([]);
    const [jumlahLaporanBT, setJumlahLaporanBT] = useState(0);
    const [jumlahLaporanWarkah, setJumlahLaporanWarkah] = useState(0);
    const beUrl = process.env.REACT_APP_BE;

    useEffect(() => {
        if(localStorage.getItem('role') !== 'admin'){
            window.location.href = '/dashboard'
        }
        axios.get(`${beUrl}/api/v1/pgsql/laporanbt`)
            .then(response => {
                setBukuTanah(response.data.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching bukutanah data: ', error);
                setIsLoading(false);
            });
    }, [beUrl]);
    
    useEffect(() => {
        axios.get(`${beUrl}/api/v1/pgsql/laporanwarkah`)
            .then(response => {
                setWarkah(response.data.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching warkah data: ', error);
                setIsLoading(false);
            });
    }, [beUrl]);

    useEffect(() => {
        axios.get(`${beUrl}/api/v1/pgsql/Jumlahlaporanbt`)
            .then(response => {
                setJumlahLaporanBT(response.data.data); 
            })
            .catch(error => {
                console.error('Error fetching jumlah laporan warkah: ', error);
            });
    }, [beUrl]);


    useEffect(() => {
        axios.get(`${beUrl}/api/v1/pgsql/Jumlahlaporanwarkah`)
            .then(response => {
                setJumlahLaporanWarkah(response.data.data); 
            })
            .catch(error => {
                console.error('Error fetching jumlah laporan warkah: ', error);
            });
    }, [beUrl]);



    const handlePrintBukuTanah = () => {
        const contentToPrint = document.getElementById('listBukuTanah');
        const namaPegawai = localStorage.getItem('namaPegawai');
        const newWindow = window.open('data:text/html,', '_blank');
        newWindow.document.write('<html><head>');
        // Menambahkan judul dengan posisi di kanan atas
        newWindow.document.write('<title style="position: absolute; top: 0; right: 20px;">Laporan Buku Tanah</title>');
        newWindow.document.write('<style>h1 { font-size: 24px; text-align: center; }</style>');   
        newWindow.document.write('</head><body>');
        // Menambahkan judul dengan garis bawah
        newWindow.document.write('<h1 style="border-bottom: 1px solid black; padding-bottom: 10px;">Daftar Jatuh Tempo Peminjaman Buku Tanah</h1>');
        newWindow.document.write(contentToPrint.outerHTML);
        // Menambahkan teks "Peminjam:" dengan nama pegawai dan tanda tangan
        newWindow.document.write('<p style="position: absolute; bottom: 180px; left: 20px;">Penanggung Jawab:</p>');
        newWindow.document.write('<p style="position: absolute; bottom: 50px; left: 20px;">' + namaPegawai + '</p>');
        // Menambahkan garis bawah
        newWindow.document.write('<hr style="position: absolute; bottom: 30px; left: 20px; width: 200px; border-top: 1px solid black;">');
        newWindow.document.write('</body></html>');
        newWindow.document.close();
        newWindow.print();
    };

    const handlePrintWarkah = () => {
        const contentToPrint = document.getElementById('listWarkah');
        const namaPegawai = localStorage.getItem('namaPegawai');
        const newWindow = window.open('data:text/html,', '_blank');
        newWindow.document.write('<html><head>');
        // Menambahkan judul dengan posisi di kanan atas
        newWindow.document.write('<title style="position: absolute; top: 0; right: 20px;">Laporan Warkah</title>');
        newWindow.document.write('<style>h1 { font-size: 24px; text-align: center; }</style>');   
        newWindow.document.write('</head><body>');
        // Menambahkan judul dengan garis bawah
        newWindow.document.write('<h1 style="border-bottom: 1px solid black; padding-bottom: 10px;">Daftar Jatuh Tempo Peminjaman Warkah</h1>');
        newWindow.document.write(contentToPrint.outerHTML);
        // Menambahkan teks "Peminjam:" dengan nama pegawai dan tanda tangan
        newWindow.document.write('<p style="position: absolute; bottom: 180px; left: 20px;">Penanggung Jawab:</p>');
        newWindow.document.write('<p style="position: absolute; bottom: 50px; left: 20px;">' + namaPegawai + '</p>');
        // Menambahkan garis bawah
        newWindow.document.write('<hr style="position: absolute; bottom: 30px; left: 20px; width: 200px; border-top: 1px solid black;">');
        newWindow.document.write('</body></html>');
        newWindow.document.close();
        newWindow.print();
    };



    return (
        
        <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-1" >
            <Card sx={{ backgroundColor: '#feeedd', color: '#f09319',padding: '10px'  }}>
                <div className="flex items-center">
                    <IoIosNotifications style={{ marginRight: '10px' }} />
                    <p>Data tersaji merupakan peminjaman yang telah melewati waktu batas maksimum</p>
                </div>
            </Card>
            <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-3">
            <InfoCard
                title="Buku Tanah"
                satuan='Buku'
                value={jumlahLaporanBT.length > 0 ? jumlahLaporanBT[0].jumlah: ''}
                >
                <RoundIcon
                    icon={FaBook}
                    iconColorClass="text-[#6993FF]"
                    bgColorClass="bg-[#BDCFFF]"
                    className="mr-4"
                />
                </InfoCard>
                <InfoCard
                title="Warkah"
                satuan='Paket'
                value={jumlahLaporanWarkah.length > 0 ? jumlahLaporanWarkah[0].jumlah: ''}
                >
                <RoundIcon
                    icon={IoLogoBuffer}
                    iconColorClass="text-[#6993FF]"
                    bgColorClass="bg-[#BDCFFF]"
                    className="mr-4"
                />
                </InfoCard> 
                <InfoCard
                title="Sertipikat Elektronik"
                satuan='Sertipikat'
                // value={tidakSesuaiValue.length > 0 ? tidakSesuaiValue[0].total: ''}
                >
                <RoundIcon
                    icon={MdLibraryBooks}
                    iconColorClass="text-[#6993FF]"
                    bgColorClass="bg-[#BDCFFF]"
                    className="mr-4"
                />
                </InfoCard> 
            </div>
            <PageTitle>Laporan Jatuh Tempo Buku Tanah</PageTitle>
            <div className="grid gap-6 mt-1 mb-2 grid-cols-1 xl:grid-cols-1">
                <ChartCard>
                    <div className="grid gap-6 mb-1 md:grid-cols-1">
                    {bukutanah && bukutanah.length > 0 && (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                style={{ marginTop: '10px', marginLeft: '10px' }}
                                endIcon={<LocalPrintshopOutlinedIcon />}
                                onClick={handlePrintBukuTanah}
                            >
                                Cetak
                            </Button>
                        </div>
                         )}
                        {isLoading ? ( 
                            <CircularProgress style={{ margin: '50px auto', display: 'block' }} />
                        ) : (
                            <>
                            {bukutanah && bukutanah.length > 0 ? (
                            <TableContainer >
                                <Table id="listBukuTanah" style={{ width: '100%', borderCollapse: 'collapse', margin: '20px 0' }}>
                                    <TableHead>
                                        <TableRow>                   
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>No</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Nomor Hak</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Nama Peminjam</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Keperluan</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Tanggal Pinjam</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Durasi</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {bukutanah?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{index + 1}</TableCell>               
                                                <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{item.nomorhak}</TableCell>
                                                <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{item.namapeminjam}</TableCell>
                                                <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{item.keperluan}</TableCell>
                                                <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{item.tglpinjam}</TableCell>
                                                <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{item.durasi}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            ) : (
                                <ChartCard>
                                    <h6 className="text-center text-sm xl:text-lg font-medium">
                                        Tidak Ada Peminjaman Buku Tanah Yang Melebihi Batas Maksimum
                                    </h6>
                                </ChartCard>
                            )}
                            </>
                        )}
                    </div>
                </ChartCard>
            </div>
   
            <PageTitle>Laporan Jatuh Tempo Warkah</PageTitle>
            <div className="grid gap-6 mt-1 mb-2 grid-cols-1 xl:grid-cols-1">
                
                <ChartCard>
                    <div className="grid gap-6 mb-1 md:grid-cols-1">
                    {warkah && warkah.length > 0 && (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                style={{ marginTop: '10px', marginLeft: '10px' }}
                                endIcon={<LocalPrintshopOutlinedIcon />}
                                onClick={handlePrintWarkah}
                            >
                                Cetak
                            </Button>
                        </div>
                          )}
                        {isLoading ? ( 
                            <CircularProgress style={{ margin: '50px auto', display: 'block' }} />
                        ) : (
                            <>
                            {warkah && warkah.length > 0 ? (
                            <TableContainer>
                                <Table id="listWarkah" style={{ width: '100%', borderCollapse: 'collapse', margin: '20px 0' }}>
                                    <TableHead>
                                        <TableRow>                   
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>No</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Nomor DI208</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Tahun DI208</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Nama Peminjam</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Keperluan</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Tanggal Pinjam</TableCell>
                                            <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Durasi</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {warkah?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{index + 1}</TableCell>               
                                                <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{item.nomordi208}</TableCell>
                                                <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{item.tahun}</TableCell>
                                                <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{item.namapeminjam}</TableCell>
                                                <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{item.keperluan}</TableCell>
                                                <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{item.tglpinjam}</TableCell>
                                                <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{item.durasi}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                           ) : (
                            <ChartCard>
                                <h6 className="text-center text-sm xl:text-lg font-medium">
                                    Tidak Ada Peminjaman Warkah Yang Melebihi Batas Maksimum
                                </h6>
                            </ChartCard>
                        )}
                        </>
                    )}
                    </div>
                </ChartCard>
            </div>
        </div>
    );
}

export default JatuhTempo;

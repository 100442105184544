import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ChartCard from "../shared/ChartCard";
import PageTitle from "../shared/PageTitle";
import RoundIcon from "../shared/RoundIcon";
import InfoCard from "../shared/InfoCard";
import { FaCheckCircle } from "react-icons/fa";
import { MdLibraryBooks } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";
import { CircularProgress,Table, TableBody, TableCell, TableContainer, TableRow, TableHead,Paper,Button } from '@mui/material';


const LogPeminjam = () => {
    const [data, setData] = useState([]);
    const [rekap, setRekap] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const itemsPerPage = 10; 
    const beUrl = process.env.REACT_APP_BE;
    const namapeminjam = localStorage.getItem('namaPegawai') || '';


    useEffect(() => {
      const fetchDataCard = async () => {
        try {
          const responseRekap = await axios.get(`${beUrl}/api/v1/pgsql/JumlahStatus`, {
            params: {
                namapeminjam: namapeminjam,
            }
        });
          
          setRekap(responseRekap.data.data);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        }
      };
  
      fetchDataCard();
    }, [namapeminjam,beUrl]);
  
    useEffect(() => {
      if(localStorage.getItem('role') !== 'peminjam'){
        window.location.href = '/dashboard'
    }

    fetchData();
  }, [currentPage]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
        const offset = (currentPage - 1) * itemsPerPage;
        const response = await axios.get(`${beUrl}/api/v1/pgsql/RiwayatPinjam`, {
            params: {
                namapeminjam: namapeminjam,
                awal: offset,
                akhir: itemsPerPage
            }
        });
        setData(response.data.data || []); 
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    setIsLoading(false);
};


      
const handleNextPage = () => {
  setIsLoading(true); 
  setCurrentPage(currentPage + 1);
};

const handlePreviousPage = () => {
  setIsLoading(true); 
  setCurrentPage(currentPage - 1);
};


  return (
    <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-1">
    <PageTitle>History Peminjaman Buku Tanah dan Warkah</PageTitle>
    <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-3">
    <InfoCard
          title="Waiting"
          satuan='Peminjaman'
          value={rekap && rekap?.length > 0 ? rekap[0].waiting : ''}
        >
        <RoundIcon
            icon={FaRegClock}
            iconColorClass="text-[#6993FF]"
            bgColorClass="bg-[#BDCFFF]"
            className="mr-4"
          />
        </InfoCard>
        <InfoCard
          title="Dipinjam"
          satuan='Peminjaman'
          value={rekap && rekap?.length > 0 ? rekap[0].dipinjam : ''}
        >
          <RoundIcon
            icon={MdLibraryBooks}
            iconColorClass="text-[#6993FF]"
            bgColorClass="bg-[#BDCFFF]"
            className="mr-4"
          />
        </InfoCard>
        <InfoCard
          title="Selesai"
          satuan='Peminjaman'
          value={rekap && rekap?.length > 0 ? rekap[0].selesai : ''}
        >
          <RoundIcon
            icon={FaCheckCircle}
            iconColorClass="text-[#6993FF]"
            bgColorClass="bg-[#BDCFFF]"
            className="mr-4"
          />
        </InfoCard>
        
    </div>
   
    <ChartCard>
   
       {isLoading ? ( 
        <CircularProgress style={{ margin: '50px auto', display: 'block' }} />
      ) : (
        <React.Fragment>
          
        <TableContainer component={Paper} style={{ marginTop: '10px'  }}>
        <Table>
          <TableHead>
              <TableRow>
              <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>No</TableCell>
              <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Nomor Hak/Warkah</TableCell>  
              <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Tahun</TableCell> 
              <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>ID Pinjam</TableCell> 
              <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Jenis Peminjaman</TableCell>  
              <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Tanggal Input</TableCell> 
              <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Status</TableCell>           
              </TableRow>
        </TableHead>
            <TableBody>
            {data.map((item, index) => {
                    const rowNumber = (currentPage - 1) * itemsPerPage + index + 1; 
                    let status;
                    let statusColor;
                    if (item.status === null) {
                        status = 'Waiting';
                        statusColor = '#ed9b40';
                    } else if (item.status === '1' && item.status_pengembalian === '2') {
                        status = 'Selesai';
                        statusColor = '#61c9a8';
                    } else if (item.status === '1' && item.status_pengembalian === null && item.status_tu === null) {
                        status = 'Dipinjam';
                        statusColor = '#d64550';
                    }  else if (item.status === '1' && item.status_pengembalian === '1' && item.status_tu ==='1') {
                      status = 'Pengembalian';
                      statusColor = '#0288d1';
                  }
                    return (
                      <TableRow key={index}>
                        <TableCell align="center">{rowNumber}</TableCell>
                        <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{item.nomorhak !== "NULL" ? item.nomorhak : item.nomordi208}</TableCell>
                        <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{item.tahun !== "null" ? item.tahun : '-'}</TableCell>
                        <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{item.paket}</TableCell>
                        <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{item.jenis}</TableCell>
                        <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{item.tglinput}</TableCell>
                        <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', color: statusColor }}>{status}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

          </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'right', marginTop: '20px' }}>
              <Button onClick={handlePreviousPage} disabled={currentPage === 1} variant="contained" color="warning" size="small" style={{ marginRight: '10px' }}>Previous</Button>
              <Button onClick={handleNextPage} disabled={data?.length < itemsPerPage} variant="contained" size="small">Next</Button>
            </div>
 </React.Fragment>
)}
    </ChartCard>
        </div>

  );
}
export default LogPeminjam;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ChartCard from "../shared/ChartCard";
import PageTitle from "../shared/PageTitle";
import RoundIcon from "../shared/RoundIcon";
import InfoCard from "../shared/InfoCard";
import { CircularProgress  } from '@mui/material';
import { IoNewspaperOutline } from "react-icons/io5";
import { RiBook2Line } from "react-icons/ri";
import { FiPieChart } from "react-icons/fi";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Table, TableBody, TableCell, TableContainer, TableRow, TableHead,Paper } from '@mui/material';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import moment from 'moment';


const DashboardTugas = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [peminjaman, setPeminjaman] = useState([]);
    const [kembali, setKembali] = useState([]);
    const [dipinjam, setDipinjam] = useState([]);
    const [kinerja, setKinerja] = useState(0); 
    const [keperluan, setKeperluan] = useState([]); 
    const [lastPeminjam, setLastPeminjam] = useState([]); 
    const beUrl = process.env.REACT_APP_BE;
    const namapeminjam = localStorage.getItem('namaPegawai') || '';

    useEffect(() => {
      const fetchDataCard = async () => {
        try {
            const responsePinjam = await axios.get(`${beUrl}/api/v1/pgsql/jumlahPinjam`);
            const responseDipinjam = await axios.get(`${beUrl}/api/v1/pgsql/totalPinjam`);
            const responseKembali = await axios.get(`${beUrl}/api/v1/pgsql/totalKembali`);
            const responseKinerja = await axios.get(`${beUrl}/api/v1/pgsql/KinerjaPetugas`);
            const responseKeperluan = await axios.get(`${beUrl}/api/v1/pgsql/JumlahKeperluan`);
            const responseLastPeminjam = await axios.get(`${beUrl}/api/v1/pgsql/LastPeminjam`);
            setPeminjaman(responsePinjam.data.data);
            setDipinjam(responseDipinjam.data.data);
            setKembali(responseKembali.data.data);
            setKinerja(responseKinerja.data.data[0].performa);
            setKeperluan(responseKeperluan.data.data);
            setLastPeminjam(responseLastPeminjam.data.data);
            setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        }
      };
  
      fetchDataCard();
    }, [namapeminjam, beUrl]);
  
    useEffect(() => {
      if(localStorage.getItem('role') !== 'petugas' && localStorage.getItem('role') !== 'tu'){
        window.location.href = '/dashboard'
    }

    fetchData();
  }, [namapeminjam, beUrl]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${beUrl}/api/v1/pgsql/LinimasaPetugas`);
      const transformedData = response.data.data.map(item => ({
        ...item,
        tglpinjam: moment(item.tglpinjam, 'DD-MM-YYYY').format('dddd')
      }));
      setData(transformedData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching chart data:', error);
      setIsLoading(false);
    }
  };
  const kekurangan =  kinerja - 100;

  return (
<div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-1">
    <PageTitle>Dashboard</PageTitle>
    <div className="grid gap-6 mt-5 grid-cols-1 xl:grid-cols-3">
    <InfoCard
          title="Total Peminjaman"
          satuan='Peminjaman'
          value={peminjaman.length > 0 ? peminjaman[0].jumlahpinjam: ''}
        >
        <RoundIcon
            icon={IoNewspaperOutline}
            iconColorClass="text-[#6993FF]"
            bgColorClass="bg-[#BDCFFF]"
            className="mr-4"
          />
        </InfoCard>
        <InfoCard
          title="Total Tunggakan"
          satuan='Peminjaman'
          value={dipinjam.length > 0 ? dipinjam[0].totaldipinjam: ''}
        >
          <RoundIcon
            icon={RiBook2Line}
            iconColorClass="text-[#6993FF]"
            bgColorClass="bg-[#BDCFFF]"
            className="mr-4"
          />
        </InfoCard>
        <InfoCard
          title="Total Pengembalian"
          satuan='Peminjaman'
          value={kembali.length > 0 ? kembali[0].totalkembali: ''}
        >
          <RoundIcon
            icon={FiPieChart}
            iconColorClass="text-[#6993FF]"
            bgColorClass="bg-[#BDCFFF]"
            className="mr-4"
          />
        </InfoCard>
    </div>
    <div className="grid gap-6 grid-cols-1 xl:grid-cols-2">
    <ChartCard >
    <h3 style={{ marginBottom: '30px' }} >Linimasa Aktivitas Dalam 7 Hari</h3>
    {isLoading ? (
        <CircularProgress style={{ margin: '50px auto', display: 'block' }} />
      ) : (
        <ResponsiveContainer width="100%" height={505}>
          <LineChart data={data}>
                    <CartesianGrid strokeDasharray="2 2" />
                    <XAxis 
                        dataKey="tglpinjam" 
                        angle={-30} 
                        textAnchor="end" 
                        interval={0} 
                        style={{ fontSize: '13px' }} 
                    />
                    
                    <YAxis />
                    <Tooltip
                        contentStyle={{
                            borderRadius: '8px',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                            padding: '8px',
                            backgroundColor: 'rgba(255, 255, 255, 0.9)'
                        }}
                    />
                    <Legend verticalAlign="bottom" wrapperStyle={{ paddingTop: "60px",  fontSize: '13px'}} />
                    <Line type="monotone" dataKey="jumlah_dipinjam" name="Jumlah Peminjaman" stroke="#0288d1" />
                    <Line type="monotone" dataKey="jumlah_dikembalikan" name="Jumlah Pengembalian" stroke="#00dffc" />
                </LineChart>
        </ResponsiveContainer>
      )}
    </ChartCard >
    <ChartCard >
        <div style={{ width: 200, margin: '0 auto', textAlign: 'center' }}>
          <h3 style={{ marginBottom: '10px' }} >Performa Peminjaman</h3>
          <CircularProgressbar
              value={kinerja}
              text={`${kinerja}%`}
              styles={buildStyles({
              textColor: "#000",
              pathColor: "#0288d1",
              trailColor: "#d6d6d6",
              strokeWidth: 2
              })}
              
            />
            <p style={{ marginTop: '15px' }}>Goal {kekurangan}%</p>
            <h3 style={{ marginBottom: '10px',marginTop: '15px' }}>Peminjaman Terakhir</h3>
            </div>
            <TableContainer component={Paper} style={{ marginTop: '30px'  }}>
        <Table>
          <TableHead>
              <TableRow>
              <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Nama Peminjam</TableCell>
              <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>ID Pinjam</TableCell>
              <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Jumlah Peminjaman</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {Array.isArray(lastPeminjam) && lastPeminjam.map((item, index) => (
                <TableRow key={index}>
                <TableCell align="center" >{item.namapeminjam}</TableCell>
                <TableCell align="center" >{item.paket}</TableCell>
                <TableCell align="center" >{Math.round(item.jumlah).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</TableCell>
            </TableRow>
            ))}
          </TableBody>
          </Table>
      </TableContainer>

    </ChartCard>
    </div>
    <div className="grid gap-6 grid-cols-1 xl:grid-cols-1">
    <ChartCard>
    <h3 style={{ marginBottom: '10px' }} >Rekapitulasi Keperluan</h3>
    {isLoading ? ( // Tampilkan CircularProgress jika isLoading true
        <CircularProgress style={{ margin: '50px auto', display: 'block' }} />
      ) : (
        
        <TableContainer component={Paper} style={{ marginTop: '20px'  }}>
        <Table>
          <TableHead>
              <TableRow>
              <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>No</TableCell>
              <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Keperluan</TableCell>
              <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Jumlah Penminjaman</TableCell>
              <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Jumlah Tunggakan</TableCell>
              <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Jumlah Pengembalian</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {keperluan.map((item, index) => (
                <TableRow key={index}>
                <TableCell align="center" >{index + 1}</TableCell>
                <TableCell align="center" >{item.keperluan}</TableCell>
                <TableCell align="center" >{Math.round(item.jumlah_dipinjam).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</TableCell>
                <TableCell align="center" >{Math.round(item.tunggakan).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</TableCell>
                <TableCell align="center" >{Math.round(item.jumlah_dikembalikan).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</TableCell>
                </TableRow>
            ))}
          </TableBody>
          </Table>
      </TableContainer>
      
      )}
    </ChartCard>
    </div>
</div>

  );
}
export default DashboardTugas;

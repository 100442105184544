import React from "react";
import { useEffect } from "react";
import Dashboard from "../../src/components/Dashboard/DashboardPinjam";

const DashboardPeminjam = () => {
  useEffect(() => {
    document.title = "SIKEBUT - Dashboard";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="p-6">
      <Dashboard/>
    </div>
  );
};

export default DashboardPeminjam;

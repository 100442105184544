import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ChartCard from "../shared/ChartCard";
import PageTitle from "../shared/PageTitle";
import RoundIcon from "../shared/RoundIcon";
import InfoCard from "../shared/InfoCard";
import { TextField,Select, MenuItem, FormControl, InputLabel,CircularProgress,Table, TableBody, TableCell, TableContainer, TableRow, TableHead,Paper,Button,Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { IoLogoBuffer } from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";
import DescriptionIcon from '@mui/icons-material/Description';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Riwayat = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sesuaiValue, setSesuaiValue] = useState(0);
    const [tidakSesuaiValue, setTidakSesuaiValue] = useState(0);
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const itemsPerPage = 10; 
    const [openDialog, setOpenDialog] = useState(false);
    const [detailData, setDetailData] = useState([]);
    const [selectedPaket, setSelectedPaket] = useState(null);
    const [selectedNamaPeminjam, setSelectedNamaPeminjam] = useState(null);
    const [status, setStatus] = useState('');
    const [pencarian, setPencarian] = useState('');
    const [inputType, setInputType] = useState(''); 
    const [namaKeperluan, setNamaKeperluan] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const beUrl = process.env.REACT_APP_BE


    useEffect(() => {
      fetchSelectData(); 
  }, [beUrl]);
  

  const fetchSelectData = async () => {
      try {        
          const cardResponse = await axios.get(`${beUrl}/api/v1/pgsql/getPetugas2`);
          const filteredData = cardResponse.data.data.filter(item => item.role === 'peminjam' );
          setNamaKeperluan(filteredData); 
          setIsLoading(false);
      } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
      }
  };

    useEffect(() => {
      const fetchDataCard = async () => {
        try {
          const responseSesuai = await axios.get(`${beUrl}/api/v1/pgsql/durasiSesuai`);
          const responseTidakSesuai = await axios.get(`${beUrl}/api/v1/pgsql/durasiMelebihi`);
          
          setSesuaiValue(responseSesuai.data.data);
          setTidakSesuaiValue(responseTidakSesuai.data.data);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        }
      };
  
      fetchDataCard();
    }, [beUrl]);
  
    useEffect(() => {
      if(localStorage.getItem('role') !== 'admin'){
        window.location.href = '/dashboard'
    }

    fetchData()
  }, [currentPage,beUrl, status]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
        const offset = (currentPage - 1) * itemsPerPage;
        const response = await axios.get(`${beUrl}/api/v1/pgsql/Riwayat`, {
            params: {
                awal: offset,
                akhir: itemsPerPage
            }
        });

        
        setData(response.data.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    setIsLoading(false);
};

      
const handleNextPage = () => {
  setIsLoading(true); 
  setCurrentPage(currentPage + 1);
};

const handlePreviousPage = () => {
  setIsLoading(true); 
  setCurrentPage(currentPage - 1);
};


const handleOpenDialog = async (paket, namapeminjam) => {
  try {
      const response = await axios.get(`${beUrl}/api/v1/pgsql/DetailRiwayat`, { params: { paket } });
      setDetailData(response.data.data);
      setSelectedPaket(paket);
      setSelectedNamaPeminjam(namapeminjam);
      setOpenDialog(true);
  } catch (error) {
      console.error('Error fetching detail data:', error);
  }
};

const handleCloseDialog = () => {
  setOpenDialog(false);
  setDetailData([]);
};

const handlePrint = () => {
  const contentToPrint = document.getElementById('listBukuTanah');
  const namaPegawai = localStorage.getItem('namaPegawai');
  const newWindow = window.open('data:text/html,', '_blank');
  newWindow.document.write('<html><head>');
  // Menambahkan judul dengan posisi di kanan atas
  newWindow.document.write('<title style="position: absolute; top: 0; right: 20px;">Riwayat Peminjaman</title>');
  newWindow.document.write('<style>h1 { font-size: 24px; text-align: center; }</style>');   
  newWindow.document.write('</head><body>');
  // Menambahkan judul dengan garis bawah
  newWindow.document.write('<h1 style="border-bottom: 1px solid black; padding-bottom: 10px;">Riwayat Peminjaman Buku Tanah Dan Warkah</h1>');
  newWindow.document.write(contentToPrint.outerHTML);
  // Menambahkan teks "Peminjam:" dengan nama pegawai dan tanda tangan
  newWindow.document.write('<p style="position: absolute; bottom: 180px; left: 20px;">Penanggung Jawab:</p>');
  newWindow.document.write('<p style="position: absolute; bottom: 50px; left: 20px;">' + namaPegawai + '</p>');
  // Menambahkan garis bawah
  newWindow.document.write('<hr style="position: absolute; bottom: 30px; left: 20px; width: 200px; border-top: 1px solid black;">');
  newWindow.document.write('</body></html>');
  newWindow.document.close();
  newWindow.print();
};

const handlePencarianChange = (event) => {
  setPencarian(event.target.value);
};

const handleStatusChange = (event) => {
  setStatus(event.target.value);
  setPencarian('');
  if (event.target.value === 'nomor') {
      setInputType('number');
  } else if (event.target.value === 'keperluan' || event.target.value === 'jenis') {
      setInputType('text');
  } else {
      setInputType('text');
  }
};


const handleSearchSubmit = async () => {
  setIsLoading(true);
  setIsSearching(true);
  try {
    const offset = (currentPage - 1) * itemsPerPage;
    let apiUrl = '';
    let params = {};

    if (status === 'nama' && pencarian.trim() !== '') {
      apiUrl = `${beUrl}/api/v1/pgsql/searchHistoryByName`;
      params = {
        namapeminjam: pencarian,
        awal: offset,
        akhir: itemsPerPage
      };
    } else if (status === 'nomor' && pencarian.trim() !== '') {
      apiUrl = `${beUrl}/api/v1/pgsql/searchHistory`;
      params = {
        nomorhak: pencarian,
        awal: offset,
        akhir: itemsPerPage
      };
    } else if (status === 'id' && pencarian.trim() !== '') {
      apiUrl = `${beUrl}/api/v1/pgsql/searchHistoryById`;
      params = {
        paket: pencarian,
        awal: offset,
        akhir: itemsPerPage
      };
    }else if (status === 'keperluan' && pencarian.trim() !== '') {
      apiUrl = `${beUrl}/api/v1/pgsql/searchHistoryByKeperluan`;
      params = {
        keperluan: pencarian,
        awal: offset,
        akhir: itemsPerPage
      };
    } else if (status === 'jenis' && pencarian.trim() !== '') {
      apiUrl = `${beUrl}/api/v1/pgsql/searchHistoryByJenis`;
      params = {
        jenis: pencarian,
        awal: offset,
        akhir: itemsPerPage
      };
    }

    if (apiUrl) {
      const response = await axios.get(apiUrl, { params });
      setSearchResults(response.data.data);
    }
  } catch (error) {
    console.error('Error fetching search results:', error);
  }
  setIsLoading(false);
};


  return (
    <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-1">
    <PageTitle>History Peminjaman Buku Tanah dan Warkah</PageTitle>
    <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-2">
    <InfoCard
          title="Pengembalian Sesuai Prosedur"
          satuan='Peminjaman'
          value={sesuaiValue?.length > 0 ? sesuaiValue[0].total: ''}
        >
        <RoundIcon
            icon={FaCheckCircle}
            iconColorClass="text-[#6993FF]"
            bgColorClass="bg-[#BDCFFF]"
            className="mr-4"
          />
        </InfoCard>
        <InfoCard
          title="Pengembalian Melebihi Prosedur"
          satuan='Peminjaman'
          value={tidakSesuaiValue?.length > 0 ? tidakSesuaiValue[0].total: ''}
        >
          <RoundIcon
            icon={IoLogoBuffer}
            iconColorClass="text-[#6993FF]"
            bgColorClass="bg-[#BDCFFF]"
            className="mr-4"
          />
        </InfoCard>
        
    </div>

    <ChartCard>
    <div className="grid gap-6 mt-5  grid-cols-1 xl:grid-cols-3">
    <FormControl fullWidth>
          <InputLabel id="select-label">Pilih Pencarian</InputLabel>
          <Select
            labelId="select-label"
            value={status}
            onChange={handleStatusChange}
            variant="outlined"
            label="Pilih Pencarian"
            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
          >
            <MenuItem value="">Pilih Pencarian</MenuItem>
            <MenuItem value="nama">Nama Peminjam</MenuItem>
            <MenuItem value="id">ID Pinjam</MenuItem>
            <MenuItem value="nomor">Nomor Hak</MenuItem>
            <MenuItem value="keperluan">Keperluan</MenuItem>
            <MenuItem value="jenis">Jenis Peminjaman</MenuItem>
          </Select>
        </FormControl>

        {status === 'nama' && (
          <FormControl fullWidth>
            <InputLabel id="nama-select-label">Pilih Peminjam</InputLabel>
            <Select
              labelId="nama-select-label"
              value={pencarian}
              onChange={handlePencarianChange}
              variant="outlined"
              label="Pilih Peminjam"
            >
              <MenuItem value="">Pilih Peminjam</MenuItem>
              {namaKeperluan.map((item) => (
                <MenuItem key={item.nama} value={item.nama}>
                  {item.nama}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {status === 'keperluan' && (
          <FormControl fullWidth>
            <InputLabel id="keperluan-select-label">Pilih Keperluan</InputLabel>
            <Select
              labelId="keperluan-select-label"
              value={pencarian}
              onChange={handlePencarianChange}
              variant="outlined"
              label="Pilih Keperluan"
            >
              <MenuItem value="">Pilih Keperluan</MenuItem>
              {namaKeperluan.map((item) => (
                <MenuItem key={item.keperluan} value={item.keperluan}>
                  {item.keperluan}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {status === 'jenis' && (
          <FormControl fullWidth>
            <InputLabel id="jenis-select-label">Peminjaman</InputLabel>
            <Select
              labelId="jenis-select-label"
              value={pencarian}
              onChange={handlePencarianChange}
              variant="outlined"
              label="Peminjaman"
            >
              <MenuItem value="BUKUTANAH">Buku Tanah</MenuItem>
              <MenuItem value="BTEL">BTEL</MenuItem>
              <MenuItem value="WARKAH">Warkah</MenuItem>
            </Select>
          </FormControl>
        )}

        {(status === 'id' || status === 'nomor') && (
          <TextField
            variant="outlined"
            fullWidth
            value={pencarian}
            onChange={handlePencarianChange}
            type={inputType}
          />
        )}

         <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
           <Button variant="contained" color="primary"  onClick={handleSearchSubmit} disabled={!pencarian.trim() || !status} >Submit</Button>
        </div>
    </div>
    </ChartCard>


    <ChartCard>
      {isLoading ? (
        <CircularProgress style={{ margin: '50px auto', display: 'block' }} />
      ) : (
        <React.Fragment>
          {isSearching && searchResults?.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>No</TableCell>
                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Nama Peminjam</TableCell>
                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Nomor Hak/Warkah</TableCell>
                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Tahun</TableCell>
                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Jenis Peminjaman</TableCell>
                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Keperluan</TableCell>
                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Tanggal Pinjam</TableCell>
                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Tanggal Kembali</TableCell>
                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Durasi</TableCell>
                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {searchResults.map((row, index) =>  {
                  const rowNumber = (currentPage - 1) * itemsPerPage + index + 1; 
                  let status;
                  let statusColor;
                  if (row.status === null) {
                      status = 'Waiting';
                      statusColor = '#ed9b40';
                  } else if (row.status === '1' && row.status_pengembalian === '2') {
                      status = 'Selesai';
                      statusColor = '#61c9a8';
                  } else if (row.status === '1' && row.status_pengembalian === '1' && row.status_tu ==='1') {
                      status = 'Pengembalian';
                      statusColor = '#0288d1';
                  }   else if (row.status === '1' && row.status_pengembalian === null && row.status_tu === null) {
                      status = 'Dipinjam';
                      statusColor = '#d64550';
                  }
                  return (
                    <TableRow key={index}>
                      <TableCell align="center">{rowNumber}</TableCell>
                      <TableCell align="center">{row.namapeminjam}</TableCell>
                      <TableCell align="center">{row.nomorhak !== "NULL" ? row.nomorhak : row.nomordi208}</TableCell>
                      <TableCell align="center">{row.tahun !== "null" ? row.tahun : '-'}</TableCell>
                      <TableCell align="center">{row.jenis}</TableCell>
                      <TableCell align="center">{row.keperluan}</TableCell>
                      <TableCell align="center">{row.tglpinjam !== null ? row.tglpinjam : '-'}</TableCell>
                      <TableCell align="center">{row.tglkembali !== null ? row.tglkembali : '-'}</TableCell>
                      <TableCell align="center">{row.durasi}</TableCell>
                      <TableCell align="center" style={{color: statusColor}}>{status}</TableCell>
                    </TableRow>
                 );
                })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            
            <TableContainer component={Paper} style={{ marginTop: '10px'  }}>
            <Table>
              <TableHead>
                  <TableRow>
                  <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>No</TableCell>
                  <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Nama Peminjam</TableCell> 
                  <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>ID Pinjam</TableCell>  
                  <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Tanggal Input</TableCell> 
                  <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Jumlah Peminjaman</TableCell> 
                  <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Jumlah Pengembalian</TableCell>  
                  <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Jumlah Tunggakan</TableCell> 
                  <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Detail</TableCell>            
                  </TableRow>
            </TableHead>
                <TableBody>
                      {data?.map((item, index) => {
                        const rowNumber = (currentPage - 1) * itemsPerPage + index + 1; // Calculate row number
    
                        return (
                          <TableRow key={index}>
                            <TableCell align="center">{rowNumber}</TableCell>
                            <TableCell align="left">{item.namapeminjam}</TableCell>
                            <TableCell align="center">{item.paket}</TableCell>
                            <TableCell align="center">{item.tglinput}</TableCell>
                            <TableCell align="center">{item.jumlah_dipinjam}</TableCell>
                            <TableCell align="center">{item.jumlah_dikembalikan}</TableCell>
                            <TableCell align="center">{item.tunggakan}</TableCell>
                            <TableCell align='center'>
                            <Button variant="outlined" color="primary" onClick={() => handleOpenDialog(item.paket, item.namapeminjam)}>
                                <DescriptionIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
              </Table>
          </TableContainer>
          )}
          <div style={{ display: 'flex', justifyContent: 'right', marginTop: '20px' }}>
            <Button onClick={handlePreviousPage} disabled={currentPage === 1} variant="contained" color="warning" size="small" style={{ marginRight: '10px' }}>Previous</Button>
            <Button onClick={handleNextPage} disabled={data?.length < itemsPerPage} variant="contained" size="small">Next</Button>
          </div>
        </React.Fragment>
      )}
    </ChartCard>

    
    <Dialog open={openDialog} onClose={handleCloseDialog} TransitionComponent={Transition} maxWidth="lg" fullWidth>
                <DialogTitle>Detail Peminjaman - {selectedNamaPeminjam} ( {selectedPaket} )</DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                style={{ marginRight: '30px' }}
                                endIcon={<LocalPrintshopOutlinedIcon />}
                                onClick={handlePrint}
                            >
                                Cetak
                            </Button>
                        </div>
                <DialogContent>
                    <TableContainer>
                    <Table id="listBukuTanah" style={{ width: '100%', borderCollapse: 'collapse', margin: '20px 0' }}>
                            <TableHead>
                                <TableRow>
                                <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>No</TableCell>
                                <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Nomor Hak/Warkah</TableCell>
                                <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Tahun</TableCell>
                                <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Jenis Peminjamman</TableCell>
                                <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Keperluan</TableCell>
                                <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Tanggal Pinjam</TableCell>
                                <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Tanggal Kembali</TableCell>
                                <TableCell align='center' style={{ backgroundColor: '#0288d1', color: 'white', border: '1px solid #ddd', padding: '8px' }}>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                              {detailData.map((detail, index) => {
                                  let status;
                                  let statusColor;
                                  if (detail.status === null) {
                                      status = 'Waiting';
                                      statusColor = '#ed9b40';
                                  } else if (detail.status === '1' && detail.status_pengembalian === '2') {
                                      status = 'Selesai';
                                      statusColor = '#61c9a8';
                                  } else if (detail.status === '1' && detail.status_pengembalian === '1' && detail.status_tu ==='1') {
                                    status = 'Pengembalian';
                                    statusColor = '#0288d1';
                                  }  else if (detail.status === '1' && detail.status_pengembalian === null && detail.status_tu === null) {
                                      status = 'Dipinjam';
                                      statusColor = '#d64550';
                                  }
                                  
                                  return (
                                      <TableRow key={index}>
                                          <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{index + 1}</TableCell>
                                          <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{detail.nomorhak !== "NULL" ? detail.nomorhak : detail.nomordi208}</TableCell>
                                          <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{detail.tahun !== "null" ? detail.tahun : '-'}</TableCell>
                                          <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{detail.jenis}</TableCell>
                                          <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{detail.keperluan}</TableCell>
                                          <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{detail.tglpinjam !== null ? detail.tglpinjam : '-'}</TableCell>
                                          <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', }}>{detail.tglkembali !== null ? detail.tglkembali : '-'}</TableCell>
                                          <TableCell align="center" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', color: statusColor }}>{status}</TableCell>
                                      </TableRow>
                                  );
                              })}
                          </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button  variant="contained" onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

  );
}
export default Riwayat;
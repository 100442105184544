import React from "react";
// import PageTitle from "./../components/shared/PageTitle";
import Login from "../Login/Login";

const Auth = () => {
  return (
    <div className="p-6">
      <div className="flex flex-1 flex-col mt-2">
      <Login/>
    </div>
    </div>
  );
};
;

export default Auth;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ChartCard from "../shared/ChartCard";
import PageTitle from "../shared/PageTitle";
import { ToastContainer, toast } from 'react-toastify';
import { Select, MenuItem, FormControl, InputLabel, TextField, Button } from "@mui/material";

const Tambah = () => {
    const [showSelectKecamatan, setShowSelectKecamatan] = useState(false);
    const [selectedKecamatan, setSelectedKecamatan] = useState('');
    const [kecamatan, setKecamatan] = useState([]);
    const [desa, setDesa] = useState([]);
    const [selectedDesa, setSelectedDesa] = useState('');
    const [jenisPeminjam, setJenisPeminjam] = useState('');
    const [showSelectTahun, setShowSelectTahun] = useState(false);
    const [nomorHak, setNomorHak] = useState('');
    const [nomorWarkah, setNomorWarkah] = useState('');
    const [tahun, setTahun] = useState('');
    const [nib, setNIB] = useState('');
    const [luas, setLuas] = useState('');
    const [pemegangHak, setPemegangHak] = useState('');
    const [tipeHak, setTipeHak] = useState('');
    const [btSend, setBtSend] = useState([]);
    const [warkahSend, setWarkahSend] = useState([]);
    const [cek, setCek] = useState(false);
    const beUrl = process.env.REACT_APP_BE;

    useEffect(() => {
        if(localStorage.getItem('role') !== 'petugas' && localStorage.getItem('role') !== 'admin'){
            window.location.href = '/dashboard';
        }
    }, []);

    useEffect(() => {
        // Fetch kecamatan data from API
        axios.get(`${beUrl}/api/v1/pgsql/Kecamatan`)
            .then(response => {
                setKecamatan(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching kecamatan data:', error);
            });
    }, [beUrl]);

    useEffect(() => {
        if (selectedKecamatan) {
            // Fetch desa data based on selected kecamatan
            axios.get(`${beUrl}/api/v1/pgsql/Desa?kecamatan=${selectedKecamatan}`)
                .then(response => {
                    setDesa(response.data.data);
                })
                .catch(error => {
                    console.error('Error fetching desa data:', error);
                });
        }
    }, [selectedKecamatan, beUrl]);

    const handleKecamatanChange = (event) => {
        setSelectedKecamatan(event.target.value);
        setDesa([]); // Reset desa when kecamatan changes
        setSelectedDesa(''); // Reset selected desa
    };

    const handleDesaChange = (event) => {
        setSelectedDesa(event.target.value);
    };

    const handleJenisPeminjamChange = (event) => {
        const selectedValue = event.target.value;
        setJenisPeminjam(selectedValue);
        setShowSelectTahun(selectedValue === "WARKAH");
        setShowSelectKecamatan(selectedValue === "BUKUTANAH");
        setNomorHak(''); // Reset nomorHak
        setNomorWarkah(''); // Reset nomorWarkah
        setTahun('');
        setLuas('');
        setNIB('');
        setTipeHak('');
        setPemegangHak('');
    };

    const handleSimpan = () => {
        if (jenisPeminjam === 'BUKUTANAH') {
            axios.post(`${beUrl}/api/v1/pgsql/insertBtTambahan`, btSend)
                .then(response => {
                    // Tampilkan toast berhasil
                    setCek(false);
                    toast.success("Penambahan Data Buku Tanah Berhasil Disimpan");
                })
                .catch(error => {
                    toast.error("Gagal Menyimpan Data Buku Tanah");
                });
        } else {
            axios.post(`${beUrl}/api/v1/pgsql/insertWarkahTambahan`, warkahSend)
                .then(response => {
                    // Tampilkan toast berhasil
                    setCek(false);
                    toast.success("Penambahan Data Warkah Berhasil Disimpan");
                })
                .catch(error => {
                    toast.error("Gagal Menyimpan Data Warkah");
                });
        }

        // Reset form fields
        setNIB('');
        setLuas('');
        setPemegangHak('');
        setTipeHak('');
        setKecamatan([]);
        setDesa([]);
        setJenisPeminjam('');
        setNomorHak(''); // Reset nomorHak
        setNomorWarkah(''); // Reset nomorWarkah
        setTahun('');
        setSelectedKecamatan('');
        setSelectedDesa('');
        setShowSelectTahun(false);
        setShowSelectKecamatan(false);
        setWarkahSend([]);
        setBtSend([]);
    };

    const handleCek = () => {
        if (jenisPeminjam === 'BUKUTANAH') {
            if (!nomorHak || !nib || !luas || !pemegangHak || !tipeHak || !selectedKecamatan || !selectedDesa) {
                toast.error("Semua form harus diisi!");
                return;
            }
    
            axios.post(`${beUrl}/api/v1/pgsql/cekBT`, {
                list: {
                    nomorHak: nomorHak,
                    nib: nib,
                    luas: luas,
                    pemegangHak: pemegangHak,
                    tipeHak: tipeHak,
                    kecamatan: selectedKecamatan,
                    desa: selectedDesa
                }
            })
            .then(response => {
                setBtSend({
                    list: [{
                        nomorHak: nomorHak,
                        nib: nib,
                        luas: luas,
                        pemegangHak: pemegangHak,
                        tipeHak: tipeHak,
                        kecamatan: selectedKecamatan,
                        desa: selectedDesa
                    }]
                });
    
                setCek(true);
                toast.success("Data Tersedia Untuk Disimpan");
            })
            .catch(() => {
                toast.error("Data sudah ada");
            });
        } else {
            if (!nomorWarkah || !tahun) {
                toast.error("Semua form harus diisi!");
                return;
            }
    
            axios.post(`${beUrl}/api/v1/pgsql/cekWarkah`, {
                list: {
                    nomorwarkah: nomorWarkah,
                    tahunwarkah: tahun
                }
            })
            .then(response => {
                setWarkahSend({
                    list: {
                        nomorwarkah: nomorWarkah,
                        tahunwarkah: tahun
                    }
                });
    
                setCek(true);
                toast.success("Data Tersedia Untuk Disimpan");
            })
            .catch(() => {
                toast.error("Data sudah ada");
            });
        }
    };
    

    return (
        <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-1">
            <ToastContainer position="top-right" />

            <PageTitle>Tambah BT / Warkah</PageTitle>
            <ChartCard>
                <div className={`grid gap-6 mb-6 ${jenisPeminjam === "WARKAH" ? 'md:grid-cols-3' : 'md:grid-cols-2'}`}>
                    <FormControl fullWidth>
                        <InputLabel id="jenis-select-label">Jenis Peminjaman</InputLabel>
                        <Select
                            labelId="jenis-select-label"
                            value={jenisPeminjam}
                            onChange={handleJenisPeminjamChange}
                            variant="outlined"
                            label="jenis peminjaman"
                            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                        >
                            <MenuItem value="">Pilih</MenuItem>
                            <MenuItem value="BUKUTANAH">Buku Tanah</MenuItem>
                            <MenuItem value="WARKAH">Warkah</MenuItem>
                        </Select>
                    </FormControl>
                    <form>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={jenisPeminjam === "BUKUTANAH" ? nomorHak : nomorWarkah}
                            label={jenisPeminjam === "BUKUTANAH" ? "Nomor Hak" : jenisPeminjam === "WARKAH" ? "Nomor DI208" : "Nomor Hak/Warkah"}
                            onChange={(e) => {
                                const value = e.target.value;
                                // Check if the value is numeric
                                if (/^\d*$/.test(value)) {
                                    if (jenisPeminjam === "BUKUTANAH") {
                                        setNomorHak(value);
                                    } else {
                                        setNomorWarkah(value);
                                    }
                                }
                            }}
                            inputProps={{
                                maxLength: 14,
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }}
                        />
                    </form>
                    {showSelectTahun && (
                        <form>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Tahun DI208"
                                value={tahun}
                                inputProps={{
                                    maxLength: 4,
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*'
                                }}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) { // Check if the value is numeric
                                        setTahun(value);
                                    }
                                }}
                            />
                        </form>
                    )}
                </div>
                {showSelectKecamatan && (
                    <>
                        <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-2">
                            <FormControl fullWidth >
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="NIB"
                                    value={nib}
                                    inputProps={{
                                        maxLength: 5,
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*'
                                    }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value)) { // Check if the value is numeric
                                            setNIB(value);
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormControl fullWidth >
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Luas (M)"
                                    value={luas}
                                    inputProps={{
                                        maxLength: 15,
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*'
                                    }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value)) { // Check if the value is numeric
                                            setLuas(value);
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormControl fullWidth >
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Pemegang Hak"
                                    value={pemegangHak}
                                    inputProps={{
                                        inputMode: 'text',
                                        style: { textTransform: 'uppercase' }
                                    }}
                                    onChange={(e) => {
                                        const value = e.target.value.toUpperCase();
                                        setPemegangHak(value);
                                    }}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="kecamatan-select-label">Tipe Hak</InputLabel>
                                <Select
                                    labelId="kecamatan-select-label"
                                    value={tipeHak}
                                    onChange={(e) => { setTipeHak(e.target.value) }}
                                    variant="outlined"
                                    label="Pilih Tipe Hak"
                                    MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                                >
                                    <MenuItem value="Hak Milik">
                                        Hak Milik
                                    </MenuItem>
                                    <MenuItem value="Hak Guna Bangunan">
                                        Hak Guna Bangunan
                                    </MenuItem>
                                    <MenuItem value="Hak Pakai">
                                        Hak Pakai
                                    </MenuItem>
                                    <MenuItem value="Hak Pengelolaan">
                                        Hak Pengelolaan
                                    </MenuItem>
                                    <MenuItem value="Hak Wakaf">
                                        Hak Wakaf
                                    </MenuItem>
                                    <MenuItem value="Surat Keputusan Pemberian Hak">
                                        Surat Keputusan Pemberian Hak
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="kecamatan-select-label">Pilih Kecamatan</InputLabel>
                                <Select
                                    labelId="kecamatan-select-label"
                                    value={selectedKecamatan}
                                    onChange={handleKecamatanChange}
                                    variant="outlined"
                                    label="Pilih Kecamatan"
                                    MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                                >
                                    <MenuItem value="">Pilih Kecamatan</MenuItem>
                                    {kecamatan?.map((item) => (
                                        <MenuItem key={item.kecamatan} value={item.kecamatan.toLowerCase()}>
                                            {item.kecamatan}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth >
                                <InputLabel id="desa-select-label">Pilih Desa</InputLabel>
                                <Select
                                    labelId="desa-select-label"
                                    value={selectedDesa}
                                    onChange={handleDesaChange}
                                    variant="outlined"
                                    label="Pilih Desa"
                                    MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                                    disabled={!selectedKecamatan}
                                >
                                    <MenuItem value="">Pilih Desa</MenuItem>
                                    {desa?.map((item) => (
                                        <MenuItem key={item.namadesa} value={item.namadesa.toLowerCase()}>
                                            {item.namadesa}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </>
                )}
                <div style={cek ? { display: 'none' } : { display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleCek} variant="contained" color="primary" style={{ marginTop: '20px' }} >CEK</Button>
                </div>
                <div style={cek ? { display: 'flex', justifyContent: 'flex-end' } : { display: 'none' }}>
                    <Button onClick={handleSimpan} variant="contained" color="warning" style={{ marginTop: '20px' }} >Simpan</Button>
                </div>
            </ChartCard>
        </div>
    );
}

export default Tambah;

import React, { useState, useEffect } from 'react';
import { CircularProgress, TextField, Button, Table, TableHead, TableBody, TableRow, TableCell,TableContainer,Paper,Checkbox   } from "@mui/material";
import ChartCard from "../shared/ChartCard";
import PageTitle from "../shared/PageTitle";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ListPinjam = () => {
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [fetchedData, setFetchedData] = useState([]);
    const [checkedItems, setCheckedItems] = useState({});
    const [allCheckedData, setAllCheckedData] = useState([])
    const [submitted, setSubmitted] = useState(false)

    const beUrl = process.env.REACT_APP_BE

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${beUrl}/api/v1/pgsql/ListPinjam?paket=${searchValue}`);
            // Membuat objek checkedItems baru yang mengatur semua status awal menjadi false
            const initialCheckedItems = response.data.data.reduce((acc, item) => {
                acc[item.id] = false;
                return acc;
            }, {});
            setCheckedItems(initialCheckedItems);
            setFetchedData(response.data.data);
            setLoading(false);
            setSubmitted(true)
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
            toast.error('Error fetching data. Please try again later.');
        }
    };

    const handleSubmitAfterVerifikasi = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${beUrl}/api/v1/pgsql/ListPinjam?paket=${searchValue}`);
            // Membuat objek checkedItems baru yang mengatur semua status awal menjadi false
            const initialCheckedItems = response.data.data.reduce((acc, item) => {
                acc[item.id] = false;
                return acc;
            }, {});
            setCheckedItems(initialCheckedItems);
            setFetchedData(response.data.data);
            setLoading(false);
        } catch (error) {
            setFetchedData([]);
            setLoading(false);
            toast.error('Daftar pinjam sudah habis');
        }
    };

    const handleVerifikasi = async () => {
        if (allCheckedData.length === 0) {
            toast.error('Pilih Daftar Pinjaman Anda');
            return;
        }
        try {
            await axios.post(`${beUrl}/api/v1/pgsql/pinjam`, { list: allCheckedData })
            .then(({data}) => {
            if(data?.data[0] === 'Buku tanah/warkah sedang dalam pinjaman'){
                toast.error(data?.data[0]);
            } else{
                toast.success(data?.data[0]);
            }

            });
            handleSubmitAfterVerifikasi();
        } catch (error) {
            console.error(error);
            toast.error('Error processing verification');
        }
    };


    const handleButtonClick = () => {
        // Call handleSubmit function to submit the form
        handleSubmit();
    };

    const handleListArray = () => {
        let arrayData = []
        fetchedData?.map((data, index) => {
            if(checkedItems[index]){
                arrayData.push(data)
            }
        })

            setAllCheckedData(arrayData)
    
    }

    const handleCheckboxChange = (id) => {
        // Memperbarui status checked atau unchecked di state
        setCheckedItems({ ...checkedItems, [id]: !checkedItems[id] });
    };

    useEffect(() => {
        if(localStorage.getItem('role') !== 'admin' && localStorage.getItem('role') !== 'petugas'){
            window.location.href = '/dashboard'
        }
        handleListArray()
    }, [checkedItems])

    return (
        <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-1">
            <PageTitle>Daftar Peminjaman</PageTitle>
            <ChartCard>
             
                    <div className="grid gap-6 mb-6 md:grid-cols-2">

                    <form style={{ marginTop: '30px' }}>
                        <TextField
                            label="Cari ID-Pinjam"
                            variant="outlined"
                            fullWidth
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </form>
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Button variant="contained" color="primary" onClick={handleButtonClick} style={{ marginTop: '30px' }}>Submit</Button>
                    </div>
                    </div>
 
                <ToastContainer position="top-right" />
            </ChartCard>
            {
                submitted ? (
            <ChartCard>
                <React.Fragment>
                    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}></TableCell>
                                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>No</TableCell>
                                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Nama Peminjam</TableCell>
                                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Nomor Hak/Warkah</TableCell>
                                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Tahun</TableCell>
                                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Jenis Peminjaman</TableCell>
                                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Keperluan</TableCell>
                                    <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Tanggal Pendaftaran</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={7} align="center">
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                ) : (   
                                    fetchedData.map((row, index) => {

                                        return (
                                        <TableRow key={index}>
                                            <TableCell align='center'>
                                            <Checkbox
                                                checked={checkedItems[index] || false}
                                                onChange={() => handleCheckboxChange(index)}
                                            />
                                            </TableCell>
                                            <TableCell align='center'>{index + 1}</TableCell>
                                            <TableCell align='center'>{row.namapeminjam}</TableCell>
                                            <TableCell align='center'>
                                                {row.nomorhak !== "NULL" ? row.nomorhak : (row.nomordi208 !== "NULL" ? row.nomordi208 : '')}
                                            </TableCell>
                                            <TableCell align="center">{row.tahun !== "null" ? row.tahun : '-'}</TableCell>
                                            <TableCell align='center'>{row.jenis}</TableCell>
                                            <TableCell align='center'>{row.keperluan}</TableCell>
                                            <TableCell align='center'>{row.tglinput}</TableCell>
                                        </TableRow>
                                        );
                                    })
                                )}
                            </TableBody>

                        </Table>
                    </TableContainer>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={() => {handleVerifikasi()}} variant="contained" color="primary" style={{ marginTop: '30px', marginLeft: '10px' }}>Verifikasi</Button>
                    </div>
                </React.Fragment>
            </ChartCard>
            ) : (
                <ChartCard>
                    <h6 className="text-center text-sm xl:text-lg font-medium">
                        Silahkan Cari Daftar Pinjam Terlebih Dahulu
                     </h6>
                </ChartCard>
            )}
            
        </div>
   
    );
}

export default ListPinjam;

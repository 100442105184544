import React, { useState, useEffect } from 'react';
import {  Select, MenuItem, FormControl, InputLabel, Button, Table, TableHead, TableBody, 
        TableRow, TableCell, TableContainer, Paper, CircularProgress, Typography, Link, 
        Dialog, DialogTitle, DialogContent, DialogActions, TextField   } from "@mui/material";
import ChartCard from "../shared/ChartCard";
import InfoCard from "../shared/InfoCard";
import PageTitle from "../shared/PageTitle";
import RoundIcon from "../shared/RoundIcon";
import KeperluanData from "../../data/keperluan.json";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaUser } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";

const Pegawai = () => {
    
    const [namaPeminjam, setNamaPeminjam] = useState('');
    const [selectedKeperluan, setSelectedKeperluan] = useState('');
    const [tableData, setTableData] = useState([]); 
    const [status, setStatus] = useState('');
    const [pengguna, setPengguna] = useState('');
    const [selectedLevel, setSelectedLevel] = useState('');
    const [statusPegawai, setStatusPegawai] = useState(null);
    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [nipbaru, setNipbaru] = useState(null)
    const [nama, setNama] = useState(null)
    const [email, setEmail] = useState(null)
    const [penggunaData, setPenggunaData] = useState([]);
    

    const beUrl = process.env.REACT_APP_BE


    const fetchDataCard = async (namaPeminjam) => {
        try {
            const responseValue = await axios.get(`${beUrl}/api/v1/pgsql/searchPetugas?nipbaru=${namaPeminjam}`);
            setValue(responseValue.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchDataCard();
    }, []);


    

    const fetchTableData = async () => {
        try {        
            const cardResponse = await axios.get(`${beUrl}/api/v1/pgsql/getPetugas2`);
            
            // Filter data status is not null
            const filteredData = cardResponse.data.data.filter(item => item.status !== null);
            setTableData(filteredData); 
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchTableData(); 
        fetchPenggunaData(); // Fetch pengguna data when component mounts
    }, []);

    const fetchPenggunaData = async (penggunaValue) => {
        try {
            const response = await axios.get(`${beUrl}/api/v1/pgsql/getPetugas3?statuspegawai=${penggunaValue}`);
            setPenggunaData(response.data.data);
        } catch (error) {
            console.error('Error fetching pengguna data:', error);
        }
    };

  
      
    const handleSubmit = async () => {
        try {
            await fetchDataCard(namaPeminjam);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleUpdate = async () => {
        try {

            if (!status) {
                toast.error('Status Petugas Harus Dipilih');
                return; 
            }
            // Kirim data ke endpoint untuk update dengan metode PUT
            await axios.put(`${beUrl}/api/v1/pgsql/updatePetugas`, { nipbaru: namaPeminjam, status: status, level: selectedLevel, keperluan: selectedLevel === 'peminjam' ? selectedKeperluan : 'NULL' })
            .finally(
                fetchTableData()
            )
            // Tampilkan pesan sukses
        
            if (status === "aktif") {
                toast.success('Petugas Berhasil Di Aktifkan');
            } else if (status === "non aktif") {
                toast.success('Petugas Berhasil Di Non Aktifkan');
            }
        } catch (error) {
            console.error('Error updating data:', error);
            // Tampilkan pesan error
            toast.error('Update Petugas Gagal');
        }
    };


    const handlePeminjamChange = (event) => {
        setNamaPeminjam(event.target.value);
    };

    const handleKeperluanChange = (event) => {
        setSelectedKeperluan(event.target.value);
    };

    const handlePenggunaChange = (event) => {
        const penggunaValue = event.target.value;
        setPengguna(penggunaValue);
        fetchPenggunaData(penggunaValue);
    };

    const handleLevelChange = (event) => {
        setSelectedLevel(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };


    const handleDialogOpen = () => {
        setDialogOpen(true);
        setStatusPegawai('');
    };

    const resetFormFields = () => {
        setNipbaru(null);
        setNama(null);
        setEmail(null);
        setStatusPegawai(null);
    };

    const handleDialogClose = () => {
        resetFormFields();
        setDialogOpen(false);
    };

    const handlePegawaiBaru = () => {
        if (!nipbaru || !nama || !email || !statusPegawai || !isValidEmail(email)) {
            toast.error("Semua form harus diisi dengan benar");
            return;
        }
        try {
            axios.post(`${beUrl}/api/v1/pgsql/insertPegawaiTambahan`, {
                pegawai: {
                    nipbaru: nipbaru,
                    nama: nama,
                    email: email,
                    statusPegawai: statusPegawai
                }
            })
            .then(response => {
                toast.success("Data baru pegawai berhasil disimpan");
                handleDialogClose();
                resetFormFields();
            })
            .catch(err => {
                toast.error(err.response.data.messages[0]);
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleStatusPegawaiChange = (event) => {
        setStatusPegawai(event.target.value);
    };

    const handleNamaChange = (event) => {
        setNama(event.target.value.toUpperCase());
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    
    const isValidEmail = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    };

    const title = pengguna === 'PPNPN' ? 'NIK' : 'NIP';

    return (
        <div className="grid gap-6 mt-5 mb-6 grid-cols-1 xl:grid-cols-1">
        <PageTitle>Manajemen Pengguna</PageTitle>
        <div className="grid gap-6  mb-6 grid-cols-1 xl:grid-cols-2">
             <div className="grid gap-6 mt-4  mb-6 md:grid-cols-1">
        <InfoCard
            title="Nama"
            satuan=''
            value={value && value.length > 0 ? String(value[0].nama) : ''}
        >
            <RoundIcon
                icon={FaUser} 
                iconColorClass="text-[#6993FF]"
                bgColorClass="bg-[#BDCFFF]"
                className="mr-4"
            />
        </InfoCard>
        <InfoCard
            title={title}
            satuan=''
            value={value && value.length > 0 ? String(value[0].nipbaru) : ''}
        >
            <RoundIcon
                icon={FaAddressCard} 
                iconColorClass="text-[#6993FF]"
                bgColorClass="bg-[#BDCFFF]"
                className="mr-4"
            />
        </InfoCard>
        <InfoCard
          title="Jabatan"
          satuan=''
          value={value && value.length > 0 && value[0].namajabatan ? String(value[0].namajabatan) : '-'
          }
        >
          <RoundIcon
            icon={FaStar}
            iconColorClass="text-[#6993FF]"
            bgColorClass="bg-[#BDCFFF]"
            className="mr-4"
          />
        </InfoCard>
        <InfoCard
          title="Status Pegawai"
          satuan=''
          value={value && value.length > 0 ? String(value[0].statuspegawai) : ''}
        >
          <RoundIcon
            icon={FaCheckCircle}
            iconColorClass="text-[#6993FF]"
            bgColorClass="bg-[#BDCFFF]"
            className="mr-4"
          />
        </InfoCard>
        </div>
        
            <ChartCard>
                <div className="grid gap-6 mb-6 md:grid-cols-1">

                <FormControl fullWidth>
                        <InputLabel id="pengguna-select-label">Pilih Pengguna</InputLabel>
                        <Select
                            labelId="pengguna-select-label"
                            value={pengguna}
                            onChange={handlePenggunaChange}
                            variant="outlined"
                            label="Pilih Pengguna"
                            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                        >
                            <MenuItem value="">Pilih Pengguna</MenuItem>
                            <MenuItem value="PNS">PNS</MenuItem>
                            <MenuItem value="PPPK">PPPK</MenuItem>
                            <MenuItem value="PPNPN">PPNPN</MenuItem>

                        </Select>
                    </FormControl>
      
                <FormControl fullWidth>
                        <InputLabel id="nama-select-label">Pilih Pegawai</InputLabel>
                        <Select
                            labelId="nama-select-label"
                            value={namaPeminjam}
                            onChange={handlePeminjamChange}
                            variant="outlined"
                            label="Pilih Pegawai"
                            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                        >
                            <MenuItem value="">Pilih Pegawai</MenuItem>
                            {penggunaData?.map((item) => (
                                <MenuItem key={item.nama} value={item.nipbaru}>
                                    {item.nama}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div> 

            

                <div className="grid gap-6 mb-6 md:grid-cols-1">
                    

                <FormControl fullWidth>
                        <InputLabel id="select-label">Level</InputLabel>
                        <Select
                            labelId="select-label"
                            value={selectedLevel}
                            onChange={handleLevelChange}
                            variant="outlined"
                            label="Level"
                            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                        >
                            <MenuItem value="peminjam">PEMINJAM</MenuItem>
                            <MenuItem value="petugas">PETUGAS BUKU TANAH</MenuItem>
                            <MenuItem value="petugas">PETUGAS WARKAH</MenuItem>
                            <MenuItem value="tu">PETUGAS TU</MenuItem>
                            <MenuItem value="admin">ADMIN</MenuItem>
                  
                        </Select>
                </FormControl>  

                <FormControl fullWidth style={selectedLevel === 'peminjam' ? { } : { display: 'none'}}>
                        <InputLabel id="select-label">Keperluan</InputLabel>
                        <Select
                            labelId="select-label"
                            value={selectedKeperluan}
                            onChange={handleKeperluanChange}
                            variant="outlined"
                            label="Keperluan"
                            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                            >
                            {/* <MenuItem value="">Keperluan</MenuItem> */}
                            {KeperluanData.data.map((item) => (
                                <MenuItem key={item.idkeperluan} value={item.keperluan}>
                                {item.keperluan}
                            </MenuItem>
                            ))}

                        </Select>
                </FormControl>  

                <FormControl fullWidth>
                        <InputLabel id="select-label">Status</InputLabel>
                        <Select
                            labelId="select-label"
                            value={status}
                            onChange={handleStatusChange}
                            variant="outlined"
                            label="Status"
                            MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                        >
                            <MenuItem value="aktif">Aktif</MenuItem>
                            <MenuItem value="non aktif">Non Aktif</MenuItem>
                  
                        </Select>
                </FormControl>

                </div>

                <Typography variant="body2" style={{marginBottom: 10 }}>
                <Link href="#" onClick={handleDialogOpen} >
                  Tambah Pengguna
                </Link>
              </Typography>
              
                 <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                 <Button variant="contained" color="primary" style={{ marginTop: '15px',marginLeft: '10px' }}  onClick={handleUpdate}>Update</Button>
                 <Button variant="contained" color="primary" style={{ marginTop: '15px',marginLeft: '10px' }} onClick={handleSubmit} >Submit</Button>
                 
                </div>
                <ToastContainer position="top-right" />

            </ChartCard>
            
        </div>
        <PageTitle>Daftar Pengguna</PageTitle>
            <div className="grid gap-6  mb-6 grid-cols-1 xl:grid-cols-1">
            <ChartCard>
            {isLoading ? ( // Tampilkan CircularProgress jika isLoading true
                <CircularProgress style={{ margin: '50px auto', display: 'block' }} />
            ) : (
            <TableContainer component={Paper} style={{ marginTop: '10px'  }}>
                <Table>
                    <TableHead>
                        <TableRow>                       
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>No</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Nama Petugas</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Status</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Tanggal Aktif</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Tanggal Non Aktif</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Role</TableCell>
                            <TableCell align='center' style={{ backgroundColor: ' #0288d1', color: 'white' }}>Keperluan</TableCell>
                         
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((item, index) => (
                            <TableRow key={item.nipbaru}>
                            <TableCell align='center' >{index + 1}</TableCell>
                            <TableCell align='center' >{item.nama}</TableCell>
                            <TableCell align='center' >{item.status}</TableCell>
                            <TableCell align='center'>{item.tanggalaktif ? new Date(item.tanggalaktif).toLocaleDateString() : '-'}</TableCell>
                            <TableCell align='center'>{item.tanggalnonaktif && new Date(item.tanggalnonaktif) >= new Date(item.tanggalaktif) ? new Date(item.tanggalnonaktif).toLocaleDateString() : '-'}
                            </TableCell>
                            <TableCell align='center' >{item.role}</TableCell>
                            <TableCell align='center' >{item.keperluan !== 'NULL' ? item.keperluan : ''}</TableCell>

                    </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </TableContainer>
                 )}
                 </ChartCard>
             </div>
             <Dialog open={dialogOpen} onClose={handleDialogClose}>
            <DialogTitle>Tambah Pengguna</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    name="nama"
                    label="Nama"
                    type="text"
                    fullWidth
                    value={nama || ''}
                    onChange={handleNamaChange}
                />
                <TextField
                    margin="dense"
                    name="nip"
                    label="NIP"
                    type="text"
                    fullWidth
                    value={nipbaru || ''}
                    inputProps={{
                        maxLength: 18,
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                    }}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) { 
                            setNipbaru(value);
                        }
                    }}
                />
                <TextField
                    margin="dense"
                    name="email"
                    label="Email"
                    type="text"
                    fullWidth
                    value={email || ''}
                    onChange={handleEmailChange}
                    error={email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)}
                    helperText={email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) ? 'Invalid email format' : ''}
                />
                <FormControl fullWidth margin="dense">
                    <InputLabel>Status Pegawai</InputLabel>
                    <Select
                        name="status pegawai"
                        label="Status Pegawai"
                        value={statusPegawai || ''}
                        onChange={handleStatusPegawaiChange}
                    >
                        <MenuItem value="PNS">PNS</MenuItem>
                        <MenuItem value="PPPK">PPPK</MenuItem>
                        <MenuItem value="PPNPN">PPNPN</MenuItem>
                    </Select>
                </FormControl>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} color="primary">Cancel</Button>
                <Button onClick={handlePegawaiBaru} color="primary">Simpan</Button>
            </DialogActions>
        </Dialog>
        </div>
   
    );
}

export default Pegawai;
